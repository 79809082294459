import React from "react";
import { Container} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import QueryHelper from "../../helpers/query-params-helper";
import authService from "../../services/AuthService"

import "./login.scss";
import {replaceSpaceOnPlus} from "../../helpers/replaceSpaceOnPlus";
import FormikInput from "../../components/FormikComponents/FormikInput";
import {validationSchema} from "../../validators/changePassword/form";
import {Formik} from "formik";

const ConfirmEmailPage = () => {
    const { email, token } = QueryHelper(["email", "token"]);
    const history = useHistory()


    const handleValidSubmit = ({ password, confirmPassword }) => {
        authService.confirmEmail({email: replaceSpaceOnPlus(email), token, password, confirmPassword})
            .then(() => {
                history.push("/login")
            })
    };

    return (
        <Container className="login-container">
            <div className="login-wr">
                <div className="login-head">
                    <Link to="/" className="title">
                        CertiHire
                    </Link>
                </div>
                <div>
                    <Formik
                        initialValues={{email: replaceSpaceOnPlus(email), password: "", confirmPassword: ""}}
                        validationSchema={validationSchema}
                        onSubmit={handleValidSubmit}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, setFieldValue, values, isValid, ...p}) => {

                            return (

                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                    <div>
                                        <FormikInput
                                            name="email"
                                            label="Email"
                                            className="form-control"
                                            placeholder="Enter email"
                                            type="email"
                                            id="email"
                                            containerClassName="creation-job-card-group"
                                            readOnly
                                        />
                                        <FormikInput
                                            name="password"
                                            label="New Password"
                                            className="form-control"
                                            placeholder="Enter new password"
                                            type="password"
                                            id="zipCode"
                                            containerClassName="creation-job-card-group"
                                        />
                                        <FormikInput
                                            id="certificatesTypes"
                                            containerClassName="creation-job-card-group"
                                            name="confirmPassword"
                                            label="Confirm New Password"
                                            className="form-control"
                                            placeholder="Confirm new password"
                                            type="password"
                                        />
                                    </div>
                                    <button className="login-bth" type="submit" disabled={!(isValid)}>
                                        Create a new password
                                    </button>
                                </form>
                            )
                        }}
                    </Formik>

                </div>
            </div>

        </Container>
    );
};

export default ConfirmEmailPage