import React, {useEffect, useMemo, useState} from "react";
import { useHistory, useParams} from "react-router-dom";

// formik-validation
import {Field, Formik} from "formik";
import {validationSchema} from "../../validators/creationJobFormValidation/form";
import {
    EDIT_JOB_MODAL_TEXT,
    EDIT_JOB_MODAL_TITLE, EMPTY_DESCRIPTION_CONTAINER,
    FALSE_RADIO_STATUS,
    MAX_LENGTH,
    MIN_POSITION_SEARCH_CHAR,
    TRUE_RADIO_STATUS,
    ZIP_MAX_LENGTH
} from "../../constants/job-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import GoogleAutocomplete from "../../components/JobCreationForm/GoogleAutocomplete"

import jobsService from "../../services/JobsService"
import {removeEmptyFields} from "../../helpers/remove-epmty-object-fields"

import "./JobEditForm.scss";
import TextEditor from "../../components/TextEditor/TextEditor";
import {convertDataFromHtml} from "../../helpers/convertFromHtml";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import MultiSelectComponent from "../../components/MultiSelectComponent/MultiSelectComponent";
import ButtonsContainer from "../../components/ButtonsContainer/ButtonsContainer";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import ModalExample from "../../components/Modal/Modal";


const breadcrumbs = [
    {
        linkTo: "/job-listing/list-of-jobs",
        title: "Job Listing",
    },
    {
        linkTo: "/job-listing/job-edit",
        title: "Job Editing",
    },
];


const JobEditForm = () => {
    const [item, setItem] = useState({})
    const [positionSearchValue, setPositionSearchValue] = useState('')
    const [certificatesSearchValue, setCertificatesSearchValue] = useState('')
    const [positionsList, setPositionsList] = useState([])
    const [certificatesList, setCertificatesList] = useState([])
    const [description, setDescription] = useState('')
    const [isDisabledButton, setIsDisabledButton] = useState(false)
    const [isModal, setIsModal] = useState(false);
    const [isDirty, setIsDirty] = useState(false)

    const history = useHistory()
    const params = useParams()

    const handlePositionSearching = (value) => {
        setPositionSearchValue(value)
    }

    const handleCertificateSearching = (value) => {
        setCertificatesSearchValue(value)
    }

    const handleGetDescription = (value) => {
        setDescription(value)
    }

    const getPositionsList = () => {
        jobsService.getPositionsList(positionSearchValue)
            .then((response) => {
                const newData = response.data.map((item) => {
                    return {...item, label: item.name}
                })
                setPositionsList(newData)
            })
    }

    const getCertificatesList = () => {
        jobsService.getCertificatesList(certificatesSearchValue)
            .then((response) => {
                const newData = response.data.map((item) => {
                    return {...item, label: item.type}
                })
                setCertificatesList(newData)
            })
    }

    useEffect(() => {

        if (positionSearchValue.length >= MIN_POSITION_SEARCH_CHAR) {
            getPositionsList()

        }
    }, [positionSearchValue])

    useEffect(() => {
        if (certificatesSearchValue.length >= MIN_POSITION_SEARCH_CHAR) {

            getCertificatesList()
        }
    }, [certificatesSearchValue])

    useEffect(() => {
        jobsService.getJobById(params.id)
            .then((response) => {
                const clearData = response?.data
                setItem(clearData)
            })

    }, [])

    const initialValues = useMemo(() => {
        return {
            location: item?.location,
            positionId: item?.position?.id,
            isUnion: item?.isUnion ? TRUE_RADIO_STATUS : FALSE_RADIO_STATUS,
            certificatesTypesIds: item?.certificatesTypes?.map(({id}) => id),
            zipCode: item?.zipCode || "",
            city: item?.city || "",
            description: item?.description || ''
        }
    }, [item]);

    const handleEditJob = ({
                               certificatesTypesIds,
                               city,
                               isUnion,
                               location,
                               positionId,
                               zipCode,
                           }) => {


        const data = {
            zipCode,
            positionId,
            isUnion: isUnion === TRUE_RADIO_STATUS,
            certificatesTypesIds,
            city,
            description: description,
            location: location ? location : {}
        };
        setIsDisabledButton(true)
        jobsService.editJob(item.id, removeEmptyFields(data))
            .then(() => {
                history.push("/job-listing/list-of-jobs")
            })
            .finally(() => setIsDisabledButton(false))

    }

    const toggle = () => setIsModal(!isModal);

    const onHandleDirtyData = (touchedData) => {
        const touchedObj = touchedData
        delete touchedObj.description

        const isFieldIsTouched = !!Object.keys(touchedObj)?.length
        const isDescriptionChanged = !(description === initialValues.description)
        return isFieldIsTouched || isDescriptionChanged
    }

    const handleCancelButtonClick = (touched) => {
        if (onHandleDirtyData(touched)) {
            toggle()
        } else {
            history.push("/job-listing/list-of-jobs")
        }
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle}/>
            {item.hasOwnProperty("position") &&
            <Card className="creation-job-card">
                <CardBody className="creation-job-card">
                    <div className="creation-job-title">Job Editing</div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleEditJob}
                        validateOnMount
                    >
                        {({errors, touched, dirty, handleSubmit, setFieldValue, values, isValid, ...p}) => {
                            setIsDirty(onHandleDirtyData(touched))
                            return (
                                <form className="form-horizontal  creation-job-form" onSubmit={handleSubmit}>
                                    <div className="creation-job-form-group">
                                        <GoogleAutocomplete
                                            name="location"
                                            type="text"
                                            label="Address"
                                            error={errors}
                                            onSelect={(addressObject) => {
                                                setFieldValue("location", addressObject)
                                            }}
                                            placeholder="Input Address"
                                            placeId={values?.location?.placeId}
                                            allowClear
                                            maxLength={MAX_LENGTH}
                                        />
                                        <SelectComponent
                                            id="positionId"
                                            label="Position/Title*"
                                            name="positionId"
                                            search={positionSearchValue}
                                            options={positionsList}
                                            defaultValue={{...item.position, label: item.position.name}}
                                            placeholder={"Input Position/Title"}
                                            onInputChange={handlePositionSearching}
                                        />
                                        <div className="creation-job-form-fieldset union-container">
                                            <div role="group" aria-labelledby="my-radio-group">
                                                <label className="custom-radio-label">
                                                    <Field className="custom-radio" type="radio" name="isUnion"
                                                           value={TRUE_RADIO_STATUS}/>
                                                    Union
                                                </label>
                                                <label className="custom-radio-label">
                                                    <Field className="custom-radio" type="radio" name="isUnion"
                                                           value={FALSE_RADIO_STATUS}/>
                                                    Non-union
                                                </label>
                                            </div>
                                            <em className={'required-notification'}>* - is required field;</em>
                                        </div>
                                        <MultiSelectComponent
                                            id="certificatesTypesIds"
                                            name="certificatesTypesIds"
                                            label="Certificates"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input Certificates"
                                            search={certificatesSearchValue}
                                            options={certificatesList}
                                            defaultValue={item.certificatesTypes.map((item) =>{
                                                return {...item, label: item.type}
                                            })}
                                            onInputChange={handleCertificateSearching}
                                        />
                                        <FormikInput
                                            name="zipCode"
                                            label="Zip Code"
                                            type="text"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input Zip Code"
                                            id="zipCode"
                                            containerClassName="creation-job-card-group"
                                            maxLength={ZIP_MAX_LENGTH}
                                        />
                                        <FormikInput
                                            name="city"
                                            label="City"
                                            type="text"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input City"
                                            id="city"
                                            containerClassName="creation-job-card-group"
                                            maxLength={MAX_LENGTH}
                                        />
                                        <TextEditor
                                            name="description"
                                            label="Description*"
                                            placeholder="Input Description"
                                            id="description"
                                            description={convertDataFromHtml(item?.description || EMPTY_DESCRIPTION_CONTAINER)}
                                            onGetDescription={handleGetDescription}
                                        />
                                    </div>
                                    <ButtonsContainer
                                        isDisabled={!(isValid) || isDisabledButton}
                                        onCancelButtonClick={() => handleCancelButtonClick(touched)}
                                        submitButtonContent={"Save"}
                                    />
                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            onToggle={toggle}
                                            path={"/job-listing/list-of-jobs"}
                                        />}
                                        isModal={isModal}
                                        title={EDIT_JOB_MODAL_TITLE}
                                        text={EDIT_JOB_MODAL_TEXT}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
            }
        </>
    );
};


export default JobEditForm;

