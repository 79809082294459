import React from "react";

import './EmploymentForms.scss';

const EmploymentFormsDownload = ({ viewFunction, downloadFunction }) => (
  <div className="download-container">
    <button
      type="button"
      onClick={viewFunction}
      onKeyPress={viewFunction}
      className="employment-forms-tabel-view"
    >
      View Application
    </button>
    <button
      type="button"
      onClick={downloadFunction}
      onKeyPress={downloadFunction}
      className="employment-forms-tabel-bth"
    >
      <span>Download</span>
    </button>
  </div>
);

export default EmploymentFormsDownload;
