import React from "react";
import {useHistory, useLocation} from "react-router-dom"

import {Button, Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import "./SendEmail.scss"
import {Formik} from "formik";
import {validationSchema} from "../../validators/sendEmailValitors/form";
import {MAX_LENGTH, MAX_MESSAGE_FIELD} from "../../constants/job-creation-constants";
import FormikInput from "../../components/FormikComponents/FormikInput";
import jobServices from "../../services/JobsService"
import {toast} from "react-toastify";

const breadcrumbs = [
    {
        linkTo: "/job-listing/list-of-jobs",
        title: "Job Listing",
    },
    {
        linkTo: "/job-listing/send-email",
        title: "Send email",
    },
];


const SendEmail = () => {
    const history = useHistory()
    const location = useLocation()
    const email = location.state.email

    const initialValues = {
        email: email,
        subject: '',
        message: '',
    }

    const handleSendEmail = ({email, subject, message} ) => {
        subject = subject ? subject : null
        jobServices.sendEmail({email, subject, message})
            .then(() =>{
                toast.success(`Email has been successfully sent`);
                toast.clearWaitingQueue()
                history.push("/job-listing/cv-searching")
            })
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Card className="send-email-card-body">
                <CardBody >
                    <div className="job-list-forms-title send-email-title" >Send email</div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSendEmail}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, setFieldValue, values, isValid, ...p}) => {

                            return (

                                <form className="form-horizontal  creation-job-form" onSubmit={handleSubmit}>
                                    <div className="creation-job-form-group">

                                        <FormikInput
                                            id="email"
                                            label="Email"
                                            name="email"
                                            containerClassName="creation-job-card-group"
                                            type="text"
                                            placeholder="Email"
                                            readOnly={true}
                                        />
                                        <FormikInput
                                            id="subject"
                                            containerClassName="creation-job-card-group"
                                            type="text"
                                            name="subject"
                                            label="Subject"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input Subject"
                                            maxLength={MAX_LENGTH}
                                        />

                                        <FormikInput
                                            name="message"
                                            label="Free text"
                                            type="text"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input text"
                                            id="message"
                                            containerClassName="creation-job-card-group"
                                            maxLength={MAX_MESSAGE_FIELD}
                                        />

                                    </div>

                                    <Button
                                        className="creation-job-form-button send-email-button"
                                        type="submit"
                                        disabled={!(isValid)}
                                    >
                                        Send
                                    </Button>

                                </form>
                            )
                        }}
                    </Formik>

                </CardBody>
            </Card>
        </>
    );
};

export default SendEmail;
