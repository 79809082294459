import React from 'react';
import ReactLoading from 'react-loading';
import "./index.scss"

const Loader = () => {
    return (
        <div className={"empty-box"}>
            <ReactLoading type={"spin"} color={"#faa31b"} height={50} width={50} />
        </div>
    );
};

export default Loader;