import React from "react";

import { Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// actions
import { loginUser } from "../../store/actions";
import authValidator from "../../validators/auth.validators";
import useAvForm from "../../hooks/useAvForm";

import "./login.scss";

const Login = ({ loginUser, history }) => {
  const [formRef, hasFormError, handleFormChange] = useAvForm();

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    loginUser(values, history);
  };


  return (
    <Container className="login-container">
      <div className="login-wr">
        <div className="login-head">
          <div className="title">CertiHire</div>
          <div className="subtitle">Welcome to CertiHire</div>
          <div className="text">Sign in. To see it in action.</div>
        </div>
        <div>
          <AvForm
            className="form-horizontal"
            ref={formRef}
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v);
            }}
          >
            <div className="form-group">
              <AvField
                name="email"
                label="Email"
                className="form-control"
                placeholder="Enter email"
                type="email"
                onChange={() => handleFormChange()}
                validate={authValidator.email}
              />
            </div>

            <div className="form-group">
              <AvField
                name="password"
                label="Password"
                type="password"
                onChange={() => handleFormChange()}
                placeholder="Enter password"
                validate={authValidator.password}
              />
            </div>

            <div>
              <button className="login-bth" type="submit">
                Sign In
              </button>
            </div>

            <Link to="/forgot-password" className="login-forgot">
              Forgot your password?
            </Link>
          </AvForm>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(
  connect(null, { loginUser })(Login),
);
