

export const createCertificateSwitchersData = [
    {
        label: "Add Expiration Date",
        name: "expirationDate",
    },
    {
        label: "Issue Date",
        name: "issueDate",
    },
    {
        label: "Add Level Field",
        name: "level",
    },
    {
        label: "Add Full Name Field",
        name: "fullName",
    },
    {
        label: "Add DOB Field",
        name: "dateOfBirth",
    },
    {
        label: "Add Class",
        name: "class",
    },
    {
        label: "Add Certification Number Field ",
        name: "number",
    },
];

export const editCertificateSwitchersData = [
    {
        label: "Expiration Date",
        name: "expirationDate",
    },
    {
        label: "Issue Date",
        name: "issueDate",
    },
    {
        label: "Level Field",
        name: "level",
    },
    {
        label: "Full Name Field",
        name: "fullName",
    },
    {
        label: "DOB Field",
        name: "dateOfBirth",
    },
    {
        label: "Class",
        name: "class",
    },
    {
        label: "Certification Number Field",
        name: "number",
    },
];

export const CERTIFICATES_LIST_COLUMNS = [
    {
        label: "Name of Certificate",
        field: "name",
        sort: "asc",
    },
    {
        label: "Type",
        field: "type",
        sort: "asc",
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
    },

];

export const REQUIRED_STATUS = "Required"
export const NOT_REQUIRED_STATUS = "NotRequired"
export const TYPE = "type"

export const EDIT_CERTIFICATE_MODAL_TITLE = "Close the form"
export const EDIT_CERTIFICATE_MODAL_TEXT = "Are You sure you want to close the form?"
export const DELETE_CERTIFICATE_MODAL_TITLE = "Delete certificate"
export const DELETE_CERTIFICATE_MODAL_TEXT = "Are You sure you want to delete the chosen certificate?"

export const MIN_CERTIFICATES_LENGTH = 3
export const CERTIFICATES_VALID_ERROR =  'Must contain at least 3 characters!'
export const CERTIFICATES_TYPE_REQUIRED_ERROR =  'Certificate Type is required!'
export const CERTIFICATES_NAME_REQUIRED_ERROR =  'Certificate Name is required!'