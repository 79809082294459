import React from 'react';

const FileInteraction = ({title, id, name, onGetPdf}) => (
    <div className="employee-info-card-files">
        <div className="employee-info-card-files-title">{title}</div>
        <div>
            <button className="employee-info-card-files-btn" id={id} onClick={()=>onGetPdf(id, name)}>View</button>
            <button className="employee-info-card-files-btn" id={id} onClick={()=>onGetPdf(id, name, true)}>Download</button>
        </div>
    </div>
);


export default FileInteraction;