export const ADMINS_LIST_COLUMNS = [
    {
        label: "Name of Customer",
        field: "fullName",
        sort: "asc",
    },
    {
        label: "Company Name",
        field: "companyName",
        sort: "asc",
    },
    {
        label: "Email",
        field: "email",
        sort: "asc",
    },
    {
        label: "Phone Number",
        field: "phoneNumber",
        sort: "asc",
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
    },
];