import React, {useEffect, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./TextEditor.scss"
import "../SelectComponent/SelectComponent.scss"
import {useField} from "formik";
import {IS_LIST_PATTERN, MAX_DESCRIPTION_FIELD} from "../../constants/job-creation-constants";
import {getDescriptionWithHtml, getStringWithoutHtml, getValueWithoutSpaces} from "../../helpers/covertUtils";


const TextEditor = ({
                        name,
                        label,
                        description = "",
                        onGetDescription,
                    }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [descriptionLength, setDescriptionLength] = useState(0)
    const [valueWithTags, setValuesWithTags] = useState('')

    const [{value}, {error, touched}, {setValue, setTouched}] = useField({name});
    useEffect(() => {
        if (description) {
            setEditorState(EditorState.createWithContent(description))
        }
    }, [])

    useEffect(() => {
        if (value?.length === MAX_DESCRIPTION_FIELD) {
            setTouched(true)
        }
    }, [value])

    useEffect(() => {
        const descriptionWithHtml = getDescriptionWithHtml(editorState)
        const stringWithoutHtml = getStringWithoutHtml(descriptionWithHtml)
        const currentValue = getValueWithoutSpaces(stringWithoutHtml)

        setValuesWithTags(descriptionWithHtml)
        setDescriptionLength(currentValue?.length)
        setValue(currentValue.trim())
        onGetDescription(descriptionWithHtml)
    }, [editorState])

    const listRegEx = new RegExp(IS_LIST_PATTERN);
    const showPlaceholder = !listRegEx.test(valueWithTags)

    useEffect(() => {
        if (!showPlaceholder) {
            setTouched(true)
        }
    }, [showPlaceholder])

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    return (
        <div>
            <section className="creation-job-card-group">
                <label className="auth-form-label">{label}</label>
                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    placeholder={showPlaceholder && "Input job description"}
                    onEditorStateChange={onEditorStateChange}
                    onBlur={() => setTouched(true)}
                />
                <span className="description-counter">{descriptionLength} / {MAX_DESCRIPTION_FIELD} characters</span>
                {touched && error && <span className="error-message">{error}</span>}
            </section>

        </div>
    );
};

export default TextEditor;