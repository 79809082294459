import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { GET_EMPLOYMENT, GET_PDF } from "./actionTypes";
import EmploymentFormService from "../../services/EmploymentFormService";
import { getDataSuccess } from "./actions";
import fileSaver from "../../helpers/fileSaver";
import { PDF_DOWNLOAD } from "../../constants/message-constans";

const getEmploymentFormsAsync = async () => {
  return await EmploymentFormService.getEmploymentForms();
};

const getPdfAsync = async (id) => {
  return await EmploymentFormService.getPdf(id);
};

function* getEmploymentForms() {
  try {
    const response = yield call(getEmploymentFormsAsync);
    yield put(getDataSuccess(response));
  } catch (error) {
    toast.error(error.message);
    toast.clearWaitingQueue()
  }
}

function* getPdf({ payload: { id, email, needDownload = false } }) {
  try {
    toast.success(PDF_DOWNLOAD);
    toast.clearWaitingQueue()
    const response = yield call(getPdfAsync, id);
    if (needDownload) {
      fileSaver(response, `${email}.pdf`)
    } else {
      if (typeof window === "undefined") return;
      const url = window.URL.createObjectURL(response);
      window.open(url);
    }
  } catch (error) {
    toast.error(error.message);
    toast.clearWaitingQueue()
  }
}

export function* watchGetEmploymentForms() {
  yield takeEvery(GET_EMPLOYMENT, getEmploymentForms);
}

export function* watchGetPdf() {
  yield takeEvery(GET_PDF, getPdf);
}

function* employmentFormsSaga() {
  yield all([fork(watchGetEmploymentForms), fork(watchGetPdf)]);
}

export default employmentFormsSaga;
