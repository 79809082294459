
export const TABLE_ENTRIES = 1;
export const TABLE_DISPLAY_ENTRIES = false;
export const TABLE_INFO = false;
export const TABLE_PAGING = false;
export const TABLE_PAGE_AMOUNT = 10;
export const TABLE_SORTABLE = false;
export const POSITIONS_TABLE_SORTABLE = true;
export const EMPLOYMENT_TABLE_SORTABLE = true;

export const ASC_LABEL = "asc"
export const DESC_LABEL = "desc"