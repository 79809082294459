import React from 'react';
import "./CVStatus.scss";
import {STATUS_DIDNOT, STATUS_HIRED, STATUS_PENDING} from "../../constants/status-constants";

const CVStatus = ({status}) => {
    return (
        <div>
            {
                status === STATUS_HIRED && <div className={status && "status status-hired"}>{status}</div> ||
                status === STATUS_PENDING && <div className={status && "status status-pending"}>{status}</div> ||
                status === STATUS_DIDNOT && <div className={status && "status status-didnt"}>Didn't</div>
            }
        </div>
    );
};

export default CVStatus;