import React from "react";
import StorageService from "../../../services/StorageService";
import userPlaceholder from "../../../assets/images/user-placeholder.png";
import ReactTooltip from "react-tooltip";
import "./sidebar.scss";
import { NavLink } from "react-router-dom";
import {TOOLTIP_BACKGROUND_COLOR} from "../../../constants/layout-constantns";
import {ADMIN_ROLE} from "../../../constants/admin-constant";

const Sidebar = ({ routes}) => {
  const user = StorageService?.user?.value;

  const userNameInfo = (user?.firstName || user?.role) + " " + (user?.lastName  || user?.role ).charAt(0)
  const showFullEmail = user?.role === ADMIN_ROLE

    return (
    <div className="sidebar">
      <img src={userPlaceholder} alt="avatar" className="user-img" />
        <div className={showFullEmail ? "user-email-hover" : "user-email"} ><span data-tip="React-tooltip">{userNameInfo}</span></div>
       {showFullEmail && <ReactTooltip backgroundColor={TOOLTIP_BACKGROUND_COLOR}>{userNameInfo}</ReactTooltip>}
      <nav>
        <ul className="navigation">
            {routes.map(({path, label}) => {
                if(label) {
                    return <li key={label}>
                        <NavLink to={path} activeClassName="active">
                            <span>{label}</span>
                        </NavLink>
                    </li>
                }
                return null

            })}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
