import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

import StorageService from '../../services/StorageService';

const Authmiddleware = ({ component: Component, layout: Layout, path }) => (
    <Route
        path={path}
        render={ props => {
            const user = StorageService.user.value;
            const session = StorageService.session.value;
            if(!!user && !!session.accessToken) {
                return (
                    <Layout>
                        <Component { ...props } />
                    </Layout>
                );
            }

            return (
                <Redirect to={ { pathname: '/login', state: { from: props.location } } }/>
            );
        } }
    />
);

export default withRouter(Authmiddleware);

