import React from 'react';
import "./index.scss"

const TableCellInnerContainer = ({content}) => {
    return (
        <div className={"td-container"}>
           {content ? <span className={"td-inner"}>{content}</span> :
           <span className={"td-inner-empty"}>-</span>}
        </div>
    );
};

export default TableCellInnerContainer;