import React, {useCallback} from "react";
import handbookService from "../../services/HandbookService"
import {validateFile} from "../../validators/uploadHandbookValidator/uploadHandbookValidator";
import {toast} from "react-toastify";
import {ENGLISH_HANDBOOK_LABEL} from "../../constants/upload-handbook-constants";
import {HandbookDropzoneContainer} from "./HandbookDropzoneContainer";



export const EnglishHandbookDropzone = ( {handbook, onSetHandbook, onDeleteHandbook, isDisabled, onSetIsDisabled} ) => {

    const handleUploadEnglishHandbook = useCallback((file) => {

        try {
            validateFile(file)
            const formData = new FormData();
            formData.append("file", file);
            onSetIsDisabled(true)
            handbookService.uploadHandbook(ENGLISH_HANDBOOK_LABEL, formData)
                .then(response => {

                    onSetHandbook(response.data)
                })
                .catch(() => onSetIsDisabled(false))
        } catch ({message}) {
            toast.error(message);
            toast.clearWaitingQueue()
            onSetIsDisabled(false)
        }

    });

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (!acceptedFiles.length) return;
            acceptedFiles.forEach((file) => handleUploadEnglishHandbook(file));
        },
        [handleUploadEnglishHandbook]
    );

    return <HandbookDropzoneContainer
        handbook={handbook}
        isDisabled={isDisabled || !!handbook?.language}
        onDrop={onDrop}
        label={ENGLISH_HANDBOOK_LABEL}
        onDeleteHandbook={onDeleteHandbook}
    />

}