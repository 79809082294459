import { takeEvery, fork, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { IS_VALID_TOKEN, RESET_PASSWORD } from "./actionTypes";
import {
  SUCCESS_MESSAGE_RESET,
  ERROR_INVALID_TOKEN,
} from "../../../constants/message-constans";
import ResetPasswordService from "../../../services/ResetPasswordService";

const userResetPasswordAsync = async (data) => {
  const response = await ResetPasswordService.resetPassword(data);
  return response.data;
};

const checkValidTokenAsync = async (data) => {
  const response = await ResetPasswordService.checkValidToken(data);
  return response.data;
};

function* userResetPassword({
  payload: {
    data: { email, token, password, confirmPassword },
    history,
  },
}) {
  try {
    const response = yield call(userResetPasswordAsync, {
      email,
      token,
      password,
      confirmPassword,
    });
      history.push("/login");
      toast.success(SUCCESS_MESSAGE_RESET);
    toast.clearWaitingQueue()
  } catch (error) {
    history.push("/login");
    toast.error(error.message);
    toast.clearWaitingQueue()
  }
}

function* isValidToken({ payload: { data, history } }) {
  try {
    const response = yield call(checkValidTokenAsync, data);
    if (!response.isValid) {
      history.push("/login");
      toast.error(ERROR_INVALID_TOKEN);
      toast.clearWaitingQueue()
    }
  } catch (error) {
    toast.error(error.message);
    toast.clearWaitingQueue()
  }
}

export function* watchRestPassword() {
  yield takeEvery(RESET_PASSWORD, userResetPassword);
}

export function* watchTokenValid() {
  yield takeEvery(IS_VALID_TOKEN, isValidToken);
}

function* changePasswordSaga() {
  yield all([fork(watchRestPassword), fork(watchTokenValid)]);
}

export default changePasswordSaga;
