import STYLE from "./style";

export const customStyles = {
    option: (styles, state) => {
        const inlineStyles = {
            ...styles,
            color: STYLE.DEFAULT_COLOR,
            backgroundColor: null,
            zIndex: 99999,
            ":active": {
                ...styles[":active"],
                backgroundColor: null,
            },
            cursor: "pointer",
        };

        if (state.isFocused) {
            inlineStyles.backgroundColor = STYLE.FOCUSED_COLOR;
        }

        if (state.isSelected) {
            inlineStyles.backgroundColor = STYLE.FOCUSED_COLOR;
            inlineStyles[":active"].backgroundColor = STYLE.FOCUSED_COLOR;
        }

        return inlineStyles;
    },

    control: (styles, { isDisabled }) => ({
        ...styles,
        cursor: "text",
        boxShadow: "",
        zIndex: 0,
        border: "solid 1px #eee",
        borderRadius: 0,
        borderColor: isDisabled
            ? STYLE.OPTION_BORDER_COLOR
            : STYLE.OPTION_BORDER_DISABLED_COLOR,
        backgroundColor: isDisabled
            ? STYLE.OPTION_BACKGROUND_DEFAULT_COLOR
            : STYLE.OPTION_BACKGROUND_DEFAULT_COLOR,
        color: isDisabled
            ? STYLE.OPTION_DEFAULT_COLOR
            : STYLE.OPTION_DISABLED_COLOR,
        ":hover": {
            borderColor: isDisabled
                ? STYLE.OPTION_BORDER_COLOR
                : STYLE.OPTION_BORDER_HOVER_COLOR,
        },
    }),

    placeholder: (styles, { isDisabled }) => {
        return {
            ...styles,
            color: isDisabled
                ? STYLE.PLACEHOLDER_DISABLED_COLOR
                : STYLE.PLACEHOLDER_DEFAULT_COLOR,
        }
    },
    menuList: (provided, state) => {
        return {
            ...provided,
            zIndex: 99999,
            position: "static",

            "&::-webkit-scrollbar": {
                width: ".3125rem",
            },

            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#777",
            },
        };
    },
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        ':hover': {
            backgroundColor: data.color,
            color: 'red',
            cursor: 'pointer'
        },
    }),
};