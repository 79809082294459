import React, {useState} from "react";
import {useHistory} from "react-router-dom";

// formik-validation
import {Formik} from "formik";
import {initialValues, validationSchema} from "../../validators/creationEmployerFormValidation/form";
import {MAX_LENGTH, MAX_EMAIL_LENGTH, REPLACE_NUMBER_PATTERN} from "../../constants/employer-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import FormikInputPhone from "../../components/FormikComponents/FormikInputPhone";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import employersService from "../../services/EmployersService";
import "./EmployerCreationForm.scss";
import ButtonsContainer from "../../components/ButtonsContainer/ButtonsContainer";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import {EDIT_EMPLOYEE_MODAL_TEXT, EDIT_EMPLOYEE_MODAL_TITLE} from "../../constants/employees-constants";
import ModalExample from "../../components/Modal/Modal";



const breadcrumbs = [
    {
        linkTo: "/employers-management/list-of-employers",
        title: "Customers Management",
    },
    {
        linkTo: "/employers-management/employer-creation",
        title: "Customer Creation",
    },
];


const EmployerCreationForm = () => {
    const [isModal, setIsModal] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    const history = useHistory()

    const handleCreateEmployer = ({
                                 firstName,
                                 lastName,
                                 companyName,
                                 email,
                                 phoneNumber,
                             }) => {


        const data = {
            firstName,
            lastName,
            companyName,
            email,
            phoneNumber: "+"+phoneNumber.replace(REPLACE_NUMBER_PATTERN, ""),
        };

        employersService.createEmployer(data)
            .then(()=> history.push("/employers-management"))

    }

    const toggle = () => setIsModal(!isModal);

    const handleCancelButtonClick = (status) => {
        if (status) {
            toggle()
        } else {
            history.push(`/employers-management/list-of-employers`)
        }
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle}/>
            <Card className="creation-employer-card">
                <CardBody className="creation-employer-card">
                    <div className="creation-employer-title">Customer Creation</div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleCreateEmployer}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, dirty, setFieldValue, values, isValid, handleChange,
                              handleBlur, ...p}) => {
                            setIsDirty(dirty)
                            return (

                                <form className="form-horizontal  creation-employer-form" onSubmit={handleSubmit}>
                                    <div className="creation-employer-form-group employer-form">

                                        <FormikInput
                                            id="firstName"
                                            label="First Name*"
                                            name="firstName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            placeholder="Input First Name"
                                            maxLength={MAX_LENGTH}
                                        />


                                        <FormikInput
                                            id="lastName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            name="lastName"
                                            label="Last Name*"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Last Name"
                                            maxLength={MAX_LENGTH}
                                        />

                                        <FormikInput
                                            name="companyName"
                                            label="Company Name*"
                                            type="text"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Company Name"
                                            id="companyName"
                                            containerClassName="creation-employer-card-group"
                                            maxLength={MAX_LENGTH}
                                        />
                                        <FormikInput
                                            name="email"
                                            label="Email*"
                                            type="email"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Email"
                                            id="email"
                                            containerClassName="creation-employer-card-group"
                                            maxLength={MAX_EMAIL_LENGTH}
                                        />
                                        <FormikInputPhone
                                            name="phoneNumber"
                                            label="Phone Number*"
                                            type="tel"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Phone Number"
                                            id="phoneNumber"
                                            containerClassName="creation-employer-card-group"
                                        />

                                        {errors.phone && touched.phone && (
                                            <div className="input-feedback">{errors.phone}</div>
                                        )}
                                    </div>
                                    <ButtonsContainer
                                        isDisabled={!(isValid)}
                                        onCancelButtonClick={() => handleCancelButtonClick(dirty)}
                                    />
                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            onToggle={toggle}
                                            path={`/employers-management/list-of-employers`}
                                        />}

                                        isModal={isModal}
                                        title={EDIT_EMPLOYEE_MODAL_TITLE}
                                        text={EDIT_EMPLOYEE_MODAL_TEXT}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
        </>
    );
};


export default EmployerCreationForm;

