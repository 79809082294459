import React, {useState} from "react";
import { useHistory } from "react-router-dom";

// formik-validation
import {Formik} from "formik";
import {initialValues, validationSchema} from "../../validators/creationEmployeeFormValidation/form";
import { MAX_LENGTH} from "../../constants/job-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";

import employeesService from "../../services/EmployeesManagementService"

import {MAX_EMAIL_LENGTH, REPLACE_NUMBER_PATTERN} from "../../constants/employer-creation-constants";
import FormikInputPhone from "../../components/FormikComponents/FormikInputPhone";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import {EDIT_EMPLOYEE_MODAL_TEXT, EDIT_EMPLOYEE_MODAL_TITLE} from "../../constants/employees-constants";
import ModalExample from "../../components/Modal/Modal";



const breadcrumbs = [
    {
        linkTo: "/employees-management/list-of-employees",
        title: "Employees Management",
    },
    {
        linkTo: "/employees-management/employee-creation",
        title: "Employee Creation",
    },
];


const EmployeeCreationForm = () => {
    const [isDirty, setIsDirty] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const history = useHistory()

    const handleCreateEmployee = ({
                                      firstName,
                                      lastName,
                                      email,
                                      phoneNumber,
                                  }) => {

        const data = {
            firstName,
            lastName,
            email,
            phoneNumber: "+"+phoneNumber.replace(REPLACE_NUMBER_PATTERN, ""),
        };

        employeesService.createEmployee(data)
            .then(()=> history.push("/employees-management"))

    }
    const toggle = () => setIsModal(!isModal);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle}/>
            <ModalExample
                onToggle={toggle}
                container={<CloseModalButtons
                    onToggle={toggle}
                    path={`/employees-management/list-of-employees`}
                />}

                isModal={isModal}
                title={EDIT_EMPLOYEE_MODAL_TITLE}
                text={EDIT_EMPLOYEE_MODAL_TEXT}
            />
            <Card className="creation-job-card">
                <CardBody className="creation-job-card">
                    <div className="creation-job-title">Employee Creation</div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleCreateEmployee}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, dirty, setFieldValue, values, isValid, ...p}) => {
                            setIsDirty(dirty)
                            return (

                                <form className="form-horizontal  creation-job-form" onSubmit={handleSubmit}>
                                    <div className="creation-job-form-group">

                                        <FormikInput
                                            id="firstName"
                                            label="First Name*"
                                            name="firstName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            placeholder="Input First Name"
                                            maxLength={MAX_LENGTH}
                                        />


                                        <FormikInput
                                            id="lastName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            name="lastName"
                                            label="Last Name*"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Last Name"
                                            maxLength={MAX_LENGTH}
                                        />

                                        <FormikInput
                                            name="email"
                                            label="Email*"
                                            type="email"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Email"
                                            id="email"
                                            containerClassName="creation-employer-card-group"
                                            maxLength={MAX_EMAIL_LENGTH}
                                        />
                                        <FormikInputPhone
                                            name="phoneNumber"
                                            label="Phone Number*"
                                            type="tel"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Phone Number"
                                            id="phoneNumber"
                                            containerClassName="creation-employer-card-group"
                                        />
                                    </div>

                                    <button
                                        className="creation-job-form-button"
                                        type="submit"
                                        disabled={!(isValid)}
                                    >
                                        Add
                                    </button>

                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
        </>
    );
};


export default EmployeeCreationForm;

