import React from 'react';
import "./ButtonsContainer.scss"

const ButtonsContainer = ({
                              isDisabled,
                              onCancelButtonClick,
                              cancelButtonContent="Cancel",
                              submitButtonContent="Create"
}) => {
    return (
        <div className="button-container">
            <button
                className="cancel-button"
                type="button"
                onClick={onCancelButtonClick}
            >
                {cancelButtonContent}
            </button>
            <button
                className="submit-button"
                type="submit"
                disabled={isDisabled}
            >
                {submitButtonContent}
            </button>
        </div>
    );
};

export default ButtonsContainer;