import React, {useEffect, useMemo, useState} from "react";
import { Card, CardBody } from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import {Link} from "react-router-dom";
import employersService from "../../services/EmployersService";
import {ADMINS_LIST_COLUMNS} from "../../constants/admins-list-constants";
import {MDBDataTable} from "mdbreact";
import {
    TABLE_DISPLAY_ENTRIES,
    TABLE_ENTRIES,
    TABLE_INFO,
    TABLE_PAGE_AMOUNT,
    TABLE_PAGING, TABLE_SORTABLE
} from "../../constants/table-constant";
import Pagination from "../../components/Pagination/TablePagination";
import {JOBS_LIMIT} from "../../constants/pagination";
import "./EmployersManagement.scss"
import Loader from "../../components/Loader";

const breadcrumbs = [
    {
        linkTo: "/employers-management/list-of-employers",
        title: "Customers Management",
    },

];

const EmployersManagement = () => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)
    const [totalCount, setTotalCount] = useState([])
    const [Offset, setOffset] = useState(0)

    const updateOffset = (newOffset) =>{
        setOffset(newOffset)

    }

    const isNotEmpty = items.length


    useEffect(() => {
        setIsLoading(true)
        employersService.getEmployers(Offset)
            .then((response) => {
                setTotalCount(response.pagination.totalCount)
                setItems(
                    response.data.map(item => {
                        return {
                            id: item.id,
                            fullName: item.personalInfo.firstName + " " + item.personalInfo.lastName,
                            companyName: item.personalInfo.companyName,
                            email: item.email,
                            phoneNumber: item.phoneNumber,
                        }
                    })
                )
            })
            .finally(() => {
                setShowEmptyMessage(true)
                setIsLoading(false)
            })
    },[Offset]);


    const itemsToData = (items) => {
        return items.map((item) => {

            item.action = (
                <Link id={item.id} to={`/employers-management/employer-details/${item.id}`} className="employers-list-details-link">View Details</Link>
            )
            return item;
        });
    };


    const data = useMemo(() => {
        return {
            columns: ADMINS_LIST_COLUMNS,
            rows: itemsToData(items),
        };
    }, [items]);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Card className="employers-list-forms-card">
                <CardBody>
                    <div className="employers-list-forms-title" >
                        <div >Customers Management</div>
                        <Link className="employers-list-create-link" to="/employers-management/employer-creation">Create</Link>
                    </div>
                    {isNotEmpty ?
                        <>
                            <MDBDataTable
                                responsive
                                striped
                                bordered
                                noBottomColumns
                                data={data}
                                displayEntries={TABLE_DISPLAY_ENTRIES}
                                info={TABLE_INFO}
                                paging={TABLE_PAGING}
                                entries={TABLE_ENTRIES}
                                pagesAmount={TABLE_PAGE_AMOUNT}
                                sortable={TABLE_SORTABLE}

                                className="employers-list-forms-tabel"
                            />
                            <Pagination limit={JOBS_LIMIT}
                                        offset={Offset || 0}
                                        totalCount={totalCount}
                                        onUpdateOffset={updateOffset}/>
                        </> :
                        <div className="employers-empty-container">
                            {isLoading && <Loader/> }
                            {showEmptyMessage && <div className="employers-empty-content">There are no Customers created yet</div>}

                        </div>
                    }
                </CardBody>
            </Card>
        </>
    );
};



export default EmployersManagement