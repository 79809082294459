import React, {useEffect, useMemo, useState} from "react";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import {Link} from "react-router-dom";
import certificatesService from "../../services/CertificatesService";
import {CERTIFICATES_LIST_COLUMNS} from "../../constants/certificates-constants";
import {MDBDataTable} from "mdbreact";
import {
    TABLE_DISPLAY_ENTRIES,
    TABLE_ENTRIES,
    TABLE_INFO,
    TABLE_PAGE_AMOUNT,
    TABLE_PAGING,
    TABLE_SORTABLE
} from "../../constants/table-constant";
import Pagination from "../../components/Pagination/TablePagination";
import {JOBS_LIMIT} from "../../constants/pagination";
import "./CertificatesList.scss"
import Loader from "../../components/Loader";

const breadcrumbs = [
    {
        linkTo: "/certification-library/list-of-certificates",
        title: "Certification Library",
    },
];

const CertificatesList = () => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)
    const [totalCount, setTotalCount] = useState([])
    const [offset, setOffset] = useState(0)

    const updateOffset = (newOffset) =>{
        setOffset(newOffset)
    }

    const isNotEmpty = items.length


    useEffect(() => {
        setIsLoading(true)
        certificatesService.getCertificates(offset)
            .then((response) => {
                setTotalCount(response.pagination.totalCount)
                setItems(
                    response.data.map(({id, name, type}) => {
                        return {
                            id,
                            name,
                            type,
                        }
                    })
                )
            })
            .finally(() => {
                setShowEmptyMessage(true)
                setIsLoading(false)
            })
    },[offset]);

     const itemsToData = (items) => {
        return items.map((item) => {
            item.action = (
                <Link to={`/certification-library/detailed-certificate/${item.id}`} className="certificates-list-details-link" id={item.id}>View Details</Link>
            )
            return item;
        });
    };


    const data = useMemo(() => {
        return {
            columns: CERTIFICATES_LIST_COLUMNS,
            rows: itemsToData(items),
        };
    }, [items]);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Card className="certificates-list-forms-card">
                <CardBody>
                    <div className="certificates-list-forms-title" >
                        <div >List of Certificates</div>
                        <Link className="certificates-list-create-link" to="/certification-library/certificate-creation">Create Certificate</Link>
                    </div>
                    {isNotEmpty ?
                        <>
                            <MDBDataTable
                                responsive
                                striped
                                bordered
                                noBottomColumns
                                data={data}
                                displayEntries={TABLE_DISPLAY_ENTRIES}
                                info={TABLE_INFO}
                                paging={TABLE_PAGING}
                                entries={TABLE_ENTRIES}
                                pagesAmount={TABLE_PAGE_AMOUNT}
                                sortable={TABLE_SORTABLE}

                                className="certificates-list-forms-tabel"
                            />
                            <Pagination limit={JOBS_LIMIT}
                                        offset={offset || 0}
                                        totalCount={totalCount}
                                        onUpdateOffset={updateOffset}/>
                        </> :
                        <div className="certificates-empty-container">
                            {isLoading && <Loader/> }
                            {showEmptyMessage && <div className="certificates-empty-content">There are no certificates created yet.</div>}

                        </div>
                    }
                </CardBody>
            </Card>
        </>
    );};

export default CertificatesList