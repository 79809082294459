import Http from "./HttpService";

class ResetPasswordService extends Http {
  checkValidToken({ email, token }) {
    return this.post("verifications/token", { email, token });
  }

  resetPassword({ email, token, password, confirmPassword }) {
    return this.put("admin-verifications/password", {
      email,
      token,
      password,
      confirmPassword,
    });
  }

  updatePassword({ oldPassword, password, confirmPassword }) {
    return this.put("admin-settings/password", {
      oldPassword,
      password,
      confirmPassword,
    });
  }
}

export default new ResetPasswordService();
