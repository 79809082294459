import {
  GET_EMPLOYMENT,
  GET_EMPLOYMENT_SUCCESS,
  GET_EMPLOYMENT_ERROR,
  GET_EMPLOYMENT_DATA,
} from "./actionTypes";

const initialState = {
  changeSuccessMsg: null,
  changeError: null,
  items: null,
  pagination: null,
  id: null
};

const getEmployment = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYMENT:
      state = {
        ...state,
        changeSuccessMsg: null,
        changeError: null,
      };
      break;
    case GET_EMPLOYMENT_DATA:
      state = {
        ...state,
        items: action.payload.data,
        pagination: action.payload.pagination,
      };
      break;
    case GET_EMPLOYMENT_SUCCESS:
      state = {
        ...state,
        changeSuccessMsg: action.payload,
      };
      break;
    case GET_EMPLOYMENT_ERROR:
      state = {
        ...state,
        changeError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default getEmployment;
