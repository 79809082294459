import * as yup from "yup";
import {PASSWORD, CONFIRM_PASSWORD} from "./empoyer";
import {
    PASSWORD_MATCH_ERROR,
    PASSWORD_REQUIRED,
} from '../../constants/form-constants';

export const validationSchema = yup.object().shape({
    password: PASSWORD.required(PASSWORD_REQUIRED),
    confirmPassword: CONFIRM_PASSWORD.required(PASSWORD_REQUIRED)
        .oneOf([yup.ref("password"), null], PASSWORD_MATCH_ERROR),
});

