import React, {useState} from "react";

import './Switcher.scss';


const Switcher = ({
                      status=false,
                      onHandleSetJobStatus=()=>{},
                      name="",
                      id='',
                      onHandleChangeCertificateStatus=()=>{},
                      onHandleStatus=()=>{},
                      oneAddCertificateField=()=>{},
                      }) => {

    const [isOpen, setIsOpen] = useState(status)

    const handleChange = ({target: {checked, id, name}}) => {
        setIsOpen(checked)
        onHandleSetJobStatus(id, checked)
        onHandleStatus(id,checked)
        onHandleChangeCertificateStatus(name, checked)
        oneAddCertificateField(name, checked)
    }

    return (<label className="switch">
        <input id={id} onChange={handleChange} name={name} type="checkbox" checked={isOpen}/>
        <span className="slider round"></span>
        { isOpen ?
            <span className="text-ch text-checked">Yes</span> :
            <span className="text-ch text-not-checked">No</span>

        }

    </label>)
};

export default Switcher;












