import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import "./TablePagination.scss"

const MARGIN_PAGES_DISPLAYED = 1;
const PAGE_RANGE_DISPLAYED = 3;

function Pagination({ totalCount, limit, offset, className ="", onUpdateOffset }) {


    const handleChangePage = (page) => {
       const offset = page * limit
        onUpdateOffset(offset)
    }


    const pageCount = Math.ceil(totalCount / limit);
    const currentPage = Math.ceil(offset / limit);
    const classes = (className + ' Pagination')

    return (
        <div className={classes}>
            <ReactPaginate
                pageCount={pageCount}
                initialPage={currentPage}
                forcePage={currentPage}
                pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
                onPageChange={({ selected }) => handleChangePage(selected)}
                previousLabel={'Previous'}
                breakLabel={'...'}
                marginPagesDisplayed={MARGIN_PAGES_DISPLAYED}
            />
        </div>
    );
}



export default withRouter(Pagination);