import React from "react";
import {NavLink} from "react-router-dom"
import "./JobListNavbar.scss"
import {navJobListingTab} from "../../constants/job-list-constants";



const JobListNavbar = () => {

    return <div className="job-list-navbar">
        {
            navJobListingTab.map(({label, path}) => {
                return <NavLink
                    to={path}
                    exact
                    key={label}
                    activeClassName="job-list-navbar-link-active"
                    className="job-list-navbar-link"
                >
                    {label}
                </NavLink>
            })
        }
    </div>
}

export default JobListNavbar;