import React from 'react';

import { Switch, BrowserRouter as Router,Route } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from 'react-toastify';

import "react-toastify/dist/ReactToastify.css";


// Import Routes all
import { adminRoutes , authRoutes, superAdminRoutes, notFoundRoutes, settingsRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import StorageService from "./services/StorageService"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";
import AdminLayout from './components/layout/AdminLayout';
import SuperAdminLayout from "./components/layout/SuperAdmitLayout";

// Import scss
import "./assets/scss/theme.scss";
import Page404 from "./pages/404/404";
import {SUPER_ADMIN_ROLE} from "./constants/admin-constant";


const App = (props) => {
	const user = StorageService?.user?.value;

			const NonAuthmiddleware = ({
				component: Component,
				layout: Layout
			}) => (
					<Route
						render={props => {
						return (
					     	   <Layout>
									<Component {...props} />
								</Layout>
							);
						}}
					/>
				);

		  return (
		  		<React.Fragment>
				<Router>
					<Switch>
						{authRoutes.map((route, idx) => (
							<NonAuthmiddleware
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
							/>
						))}

						{adminRoutes.map((route, idx) => (
							<Authmiddleware
								path={route.path}
								layout={AdminLayout}
								component={route.component}
								key={idx}
							/>
						))}

						{superAdminRoutes.map((route, idx) => (
							<Authmiddleware
								path={route.path}
								layout={SuperAdminLayout}
								component={route.component}
								key={idx}
							/>
						))}
						{notFoundRoutes.map((route, idx) => (
							<Authmiddleware
								layout={user?.role === SUPER_ADMIN_ROLE ? SuperAdminLayout : AdminLayout}
								component={route.component}
								key={idx}
							/>
						))}
						<Page404/>
					</Switch>
				</Router>
        <ToastContainer
          autoClose={4000}
          closeButton={true}
          hideProgressBar={true}
          transition={Zoom}
          limit={1}
        />
			</React.Fragment>
		
		  );
		}


const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
