import * as yup from 'yup';

import {
    MIN_LENGTH,
    MIN_LENGTH_ERROR,
    MAX_LENGTH,
    MAX_LENGTH_ERROR,
    PASSWORD_VALIDATION,
    PASSWORD_VALIDATION_ERROR,
    } from '../../constants/form-constants';



export const PASSWORD = yup
    .string()
    .min(MIN_LENGTH, MIN_LENGTH_ERROR)
    .max(MAX_LENGTH, MAX_LENGTH_ERROR)
    .matches(PASSWORD_VALIDATION, PASSWORD_VALIDATION_ERROR)

export const CONFIRM_PASSWORD = yup
    .string()
    .min(MIN_LENGTH, MIN_LENGTH_ERROR)
    .max(MAX_LENGTH, MAX_LENGTH_ERROR)
    .matches(PASSWORD_VALIDATION, PASSWORD_VALIDATION_ERROR)

