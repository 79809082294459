import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {Card, CardBody} from "reactstrap";
import MaskedInput from "react-text-mask";

import Breadcrumb from "../../components/Common/breadcrumb";
import ModalExample from "../../components/Modal/Modal";

import employersService from "../../services/EmployersService";
import {DELETE_EMPLOYER_MODAL_TITLE, DELETE_EMPLOYER_MODAL_TEXT} from "../../constants/employer-edit-constants";

import {phoneNumberMask} from "../../constants/employer-creation-constants"
import "./EmployerDetails.scss";
import DeleteModalButtons from "../../components/Modal/ModalButtonContainers/DeleteModalButtons";


const EmployerDetails = () => {
    const [item, setItem] = useState({})
    const [isModal, setIsModal] = useState(false)

    const history = useHistory()
    const params = useParams()

    const breadcrumbs = [
        {
            linkTo: "/employers-management/list-of-employers",
            title: "Customers Management",
        },
        {
            linkTo: `/employers-management/employer-details/${item.id}`,
            title: "Customer Details",
        },
    ];

    const toggle = () => setIsModal(!isModal);

    useEffect(()=>{
        employersService.getEmployerById(params.id)
            .then((response)  => {
                setItem(response.data)
            })
    }, [])

    const handleDeleteEmployer = () => {
      employersService.deleteEmployer(params.id)
          .then(()=> history.push(`/employers-management/list-of-employers`))
            }

    const initialValue = {
        firstName: item?.personalInfo?.firstName,
        lastName: item?.personalInfo?.lastName,
        companyName: item?.personalInfo?.companyName,
        email: item?.email,
        phoneNumber: item?.phoneNumber,
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            {item.hasOwnProperty("phoneNumber") &&
            <Card className="creation-employer-card employer-details">
                <CardBody className="creation-employer-card">
                    <div className="creation-employer-title">Customer Details</div>
                    <form className="form-horizontal  creation-employer-form">
                        <div className="creation-employer-form-group employer-form">

                            <div className='input-container'>
                                <label>First Name</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={initialValue.firstName}
                                    readOnly
                                />
                            </div>
                            <div className='input-container'>
                                <label>Last Name</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={initialValue.lastName}
                                    readOnly
                                />
                            </div>
                            <div className='input-container'>
                                <label>Company Name</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={initialValue.companyName}
                                    readOnly
                                />
                            </div>
                            <div className='input-container'>
                                <label>Email</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={initialValue.email}
                                    readOnly
                                />
                            </div>
                            <div className='input-container'>
                                <label>Phone Number</label>
                                <MaskedInput
                                    mask={phoneNumberMask}
                                    className='form-control'
                                    type="text"
                                    value={initialValue.phoneNumber}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="employer-button-container">

                            <button
                                className="employer-details-delete-button delete-button"
                                type="button"
                                onClick={toggle}
                            >
                                Delete
                            </button>

                            <Link
                                to={`/employers-management/employer-edit/${item.id}`}
                                className="employer-details-update-button update-button"
                            >
                                Update
                            </Link>
                        </div>
                        <ModalExample
                            onToggle={toggle}
                            container={<DeleteModalButtons
                                onToggle={toggle}
                                onDelete={handleDeleteEmployer}
                            />}

                            isModal={isModal}
                            title={DELETE_EMPLOYER_MODAL_TITLE}
                            text={DELETE_EMPLOYER_MODAL_TEXT}
                        />
                    </form>
                </CardBody>
            </Card>
            }
        </>
    );
};


export default EmployerDetails;

