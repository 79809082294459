import {
    ALLOWED_EXTENTIONS_FILE,
    ERROR_ALLOWED_EXTENTION_MESSAGE,
    ERROR_LARGE_SIZE_MESSAGE,
    ERROR_SMALL_SIZE_MESSAGE,
    MAX_FILE_SIZE,
    MIN_FILE_SIZE
} from "../../constants/upload-handbook-constants";

import { isAllowedExtension } from "../../helpers/isAllowedExtention";

export const validateFile = (file) => {
    if (file?.size > MAX_FILE_SIZE ) {
        throw new Error(ERROR_LARGE_SIZE_MESSAGE);
    }

    if (file?.size < MIN_FILE_SIZE) {
        throw new Error(ERROR_SMALL_SIZE_MESSAGE);
    }

    if(!isAllowedExtension(file, ALLOWED_EXTENTIONS_FILE)) {
        throw new Error(ERROR_ALLOWED_EXTENTION_MESSAGE);
    }

    return null;
};
