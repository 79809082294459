import Http from "./HttpService";


class EmployeesService extends Http {

    createEmployee (model) {
        return this.post("admin-users", model)
    }

    getEmployees( Offset, Limit=10) {
        return this.get(`admin-users`, {params: {
                Limit,
                Offset}
        })
    }
    getEmployeeById( id) {
        return this.get(`admin-users/${id}`)
    }
    editEmployee(id, model){
        return this.put(`admin-users/${id}`, model)
    }

    deleteEmployee(id){
        return this.delete(`admin-users/${id}`)
    }
}


export default new EmployeesService()