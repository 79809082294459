import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {DELETING_TAGS_PATTERN} from "../constants/job-creation-constants";


export const getDescriptionWithHtml = (data) => {
    const dataInHtml = draftToHtml(convertToRaw(data.getCurrentContent()))
    const dataWithoutStyleTag = dataInHtml.replace(/style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi, "")
    return dataWithoutStyleTag
}

export const getStringWithoutHtml = (data) => {
    return data.replace(/\n/g, `<br/>`).replace(new RegExp(DELETING_TAGS_PATTERN, 'g'), '')
}

export const getValueWithoutSpaces = (data) => {
    return data?.replace(/&nbsp;/g, ' ')
}