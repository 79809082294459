import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import {ADMIN_ROLE} from "../../../constants/admin-constant"

import AuthService from "../../../services/AuthService";
import StorageService from "../../../services/StorageService";
import { toast } from "react-toastify";

const loginWithEmailPasswordAsync = async (email, password) => {
  const authUser = await AuthService.login({ password, email });
  return authUser.data;
};
const logoutAsync = async () => {
  if (!StorageService.session.value.accessToken) return;
  return await AuthService.signOut();
};

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      loginWithEmailPasswordAsync,
      user.email,
      user.password
    );
    yield put(loginSuccess(response));
    if(response.user.role === ADMIN_ROLE){
      history.push("/employment-forms")
    } else {
      history.push("/employers-management/list-of-employers")
    }
  } catch (error) {
    toast.error(error.message);
    toast.clearWaitingQueue()
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield call(logoutAsync);
    yield put(logoutUserSuccess());
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
