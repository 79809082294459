import React from 'react';
import { useField } from 'formik';
import MaskedInput from "react-text-mask";
import {phoneNumberMask} from "../../constants/employer-creation-constants"

const FormikInputPhone = ({
                         placeholder,
                         name,
                         type,
                         id,
                         readOnly,
                         label,
                         containerClassName,
                     }) => {

    const [{onBlur,onChange,value}, meta] = useField({ name, type, placeholder });
    
    const isError = meta.error && meta.touched;
    return (
        <section className={containerClassName}>
            <label className={isError ? "text-danger auth-form-label" : "auth-form-label"}>{label}</label>
            <MaskedInput
                name={name}
                mask={phoneNumberMask}
                onBlur={onBlur}
                id={id}
                onChange={onChange}
                value={value}
                type={type}
                className={isError ? "is-invalid form-control" : "form-control"}
                readOnly={readOnly}
                placeholder={placeholder}
            />

            <span className="invalid-feedback">{meta.error}</span>

        </section>
    )
};

export default FormikInputPhone;
