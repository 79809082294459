
export const getExtensionFile = ({ name }) => {
    return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
};

export const isAllowedExtension = (file, allowedExtensions) => {
    const fileExtension = getExtensionFile(file);

    return allowedExtensions.some(allowedExtension => {
        return fileExtension === allowedExtension;
    })
}