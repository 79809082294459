import React from "react";
import {Link} from "react-router-dom";

const CloseModalButtons = ({path, onToggle}) => {
    return <>
        <button  onClick={onToggle} className={'cancel-button'}>Cancel</button>
        <Link  to={path} className={'close-button'}>Close </Link>{' '}
     </>
}

export default CloseModalButtons