import React, {useEffect, useMemo, useState} from "react";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import ModalExample from "../../components/Modal/Modal";
import {Link, useHistory, useParams} from "react-router-dom";

import certificatesService from "../../services/CertificatesService"
import "./CertificateDetails.scss"
import {
    editCertificateSwitchersData,
    REQUIRED_STATUS,
    TYPE,
    DELETE_CERTIFICATE_MODAL_TITLE,
    DELETE_CERTIFICATE_MODAL_TEXT
} from "../../constants/certificates-constants";
import DeleteModalButtons from "../../components/Modal/ModalButtonContainers/DeleteModalButtons";
import Loader from "../../components/Loader";


const CertificateDetails = () => {
    const [item, setItem] = useState({})
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const isEmpty = item.hasOwnProperty(TYPE)
    const history = useHistory()
    const params = useParams();

    const breadcrumbs = useMemo(() => ([
        {
            linkTo: "/certification-library/list-of-certificates",
            title: "Certification Library",
        },
        {
            linkTo: `/certification-library/detailed-certificate/${params.id}`,
            title: "Detailed Certificate",
        },
    ]), [params.id])

    const handleDeleteCertificate = () => {
        certificatesService.deleteCertificate(params.id)
            .then(() => history.push("/certification-library/list-of-certificates"))
    }

    useEffect(() => {
        setIsLoading(true)
        certificatesService.getCertificateById(params.id)
            .then(response => setItem(response.data))
            .finally(() => {
                setIsLoading(false)
            })
    }, [params.id])

    const toggle = () => setIsModal(!isModal);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            <Card className="employment-forms-card">
                <CardBody className="certificate-body">
                    <div className="certificate-details-title-container">
                        <div className="certificate-details-title-content">
                            <div className="certificate-details-title">{item.name}</div>
                            <div>{item.type}</div>
                        </div>
                        <div className="button-container">
                            <button
                                className="close-button"
                                type="button"
                                onClick={toggle}
                            >
                                Delete
                            </button>

                            <Link
                                to={`/certification-library/certificate-edit/${item.id}`}
                                className=" certificate-edit-button"
                            >
                                Edit
                            </Link>
                            <ModalExample
                                onToggle={toggle}
                                isModal={isModal}
                                title={DELETE_CERTIFICATE_MODAL_TITLE}
                                text={DELETE_CERTIFICATE_MODAL_TEXT}
                                container={
                                    <DeleteModalButtons
                                        onToggle={toggle}
                                        onDelete={handleDeleteCertificate}
                                    />
                                }
                            />
                        </div>
                    </div>

                    <div className="certificate-container">
                        {isLoading && <div className="certificate-detail-empty-container">
                            <Loader/>
                        </div>}
                        {isEmpty && <>
                            <div className="certificate-field">
                                <span className="certificate-field-label">{item.type}</span>
                            </div>
                            <div className="certificate-field">
                                <span className="certificate-field-label">{item.name}</span>
                            </div>
                            {editCertificateSwitchersData.map(({label, name}) => {
                                if (item[name]) {
                                    return <div className="certificate-field" key={label}>
                                        <span className="certificate-field-label">{label}</span>
                                        {item[name] === REQUIRED_STATUS &&
                                        <span className="certificate-asterisks">*</span>
                                        }
                                    </div>
                                }
                            })
                            }
                        </>}
                    </div>

                </CardBody>
            </Card>
        </>
    );
};

export default CertificateDetails