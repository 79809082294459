import React, {useState} from "react";
import {useHistory} from "react-router-dom";

// formik-validation
import {Formik} from "formik";
import {initialValues, validationSchema} from "../../validators/creationPositionFormValidation/form";
import {MAX_LENGTH} from "../../constants/job-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import ButtonsContainer from "../../components/ButtonsContainer/ButtonsContainer";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import ModalExample from "../../components/Modal/Modal";

import positionsService from "../../services/PositionsService"
import {CREATE_POSITION_MODAL_TEXT, CREATE_POSITION_MODAL_TITLE} from "../../constants/positions-constants";
import "../JobCreationForm/JobCreationForm.scss";

const breadcrumbs = [
    {
        linkTo: "/job-listing",
        title: "Job Listing",
    },
    {
        linkTo: "/job-listing/positions-list",
        title: "Positions/Titles",
    },
    {
        linkTo: "/job-listing/position-creation",
        title: "Position creation",
    },
];

const PositionCreationForm = () => {
    const [isDisabledButton, setIsDisabledButton] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [isModal, setIsModal] = useState(false);
    const history = useHistory()

    const handleCreatePosition = ({
                                 name,
                             }) => {
        const data = {
            name,
        };
        setIsDisabledButton(true)
        positionsService.createPosition(data)
            .then(() => {
                history.push("/job-listing/positions-list")
            })
            .finally(() => setIsDisabledButton(false))
    }

    const toggle = () => setIsModal(!isModal);

    const handleCancelButtonClick = (value) => {
        if(value){
            toggle()
        } else {
            history.push("/job-listing/positions-list")
        }
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle}/>
            <Card className="creation-job-card">
                <CardBody className="creation-job-card">
                    <div className="creation-job-title">Create Position/Title</div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleCreatePosition}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, dirty, setFieldValue, values, isValid, ...p}) => {
                            setIsDirty(dirty)
                            return (

                                <form className="form-horizontal  creation-job-form" onSubmit={handleSubmit}>
                                    <div className="creation-job-form-group">

                                        <FormikInput
                                            id="name"
                                            label="Name"
                                            name="name"
                                            containerClassName="creation-job-card-group"
                                            type="text"
                                            placeholder="Input Name"
                                            maxLength={MAX_LENGTH}
                                        />

                                    </div>
                                    <ButtonsContainer
                                        isDisabled={!(isValid) || isDisabledButton}
                                        onCancelButtonClick={()=>handleCancelButtonClick(values.name)}
                                    />

                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            onToggle={toggle}
                                            path={"/job-listing/positions-list"}
                                        />}
                                        isModal={isModal}
                                        title={CREATE_POSITION_MODAL_TITLE}
                                        text={CREATE_POSITION_MODAL_TEXT}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
        </>
    );
};


export default PositionCreationForm;

