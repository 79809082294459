import React, { useEffect, useState } from "react";
import { Card, CardBody, Modal } from "reactstrap";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import resetPasswordValidator from "../../validators/reset-password.validators";
import { userUpdatePassword } from "../../store/actions";
import useAvForm from "../../hooks/useAvForm";
import authValidator from "../../validators/auth.validators";
import Breadcrumb from "../../components/Common/breadcrumb";
import "./Settings.scss";
import {ADMIN_ROLE, USER_STORAGE_ITEM} from "../../constants/admin-constant";

const SettingsPage = ({ userUpdatePassword, changeSuccessMsg }) => {
  const [role, setRole] = useState('')
  useEffect(() => setIsOpenState(changeSuccessMsg), [changeSuccessMsg]);
  useEffect(() => setIsOpenState(false), []);
  const [formRef, handleFormChange] = useAvForm();
  const [isOpenModal, setIsOpenState] = useState(false);

  useEffect(()=> {
    const user = JSON.parse(sessionStorage.getItem(USER_STORAGE_ITEM))
     setRole(user.role)
  }, [])


  const handleValidSubmit = (event, data) => {
      userUpdatePassword(data);
    formRef.current._inputs.confirmPassword.value = ""
    formRef.current._inputs.password.value = ""
    formRef.current._inputs.oldPassword.value = ""
  };

  const breadcrumbs = [
    {
      linkTo: role === ADMIN_ROLE ? "/employment-forms": "/employers-management/list-of-employers",
      title: "Home",
    },
    {
      linkTo: role === ADMIN_ROLE ? "/settings" : "/settings-super",
      title: "Settings",
    },
  ];

  return (
    <>
      <Modal isOpen={isOpenModal} centered className="settings-modal">
        <button
          type="button"
          onClick={() => setIsOpenState(!isOpenModal)}
          onKeyPress={() => setIsOpenState(!isOpenModal)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-text">Password is changed</div>
        <button
          type="button"
          className="bth-ok"
          onClick={() => setIsOpenState(!isOpenModal)}
          onKeyPress={() => setIsOpenState(!isOpenModal)}
          data-dismiss="modal"
          aria-label="ok"
        >
          <span>Ok</span>
        </button>
      </Modal>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Card className="settings-card">
        <CardBody>
          <div className="settings-title">Change Password</div>
          <div className="settings">
            <AvForm
              className="form-horizontal"
              ref={formRef}
              onValidSubmit={(e, v) => handleValidSubmit(e, v)}
            >
              <AvField
                name="oldPassword"
                label="Old Password"
                className="form-control"
                placeholder="Enter old password"
                type="password"
                onChange={handleFormChange}
                validate={authValidator.password}
              />

              <div className="form-group">
                <AvField
                  name="password"
                  label="New Password"
                  className="form-control"
                  placeholder="Enter new password"
                  type="password"
                  onChange={handleFormChange}
                  validate={resetPasswordValidator.password}
                />
              </div>
              <div className="form-group">
                <AvField
                  name="confirmPassword"
                  label="Confirm New Password"
                  className="form-control"
                  placeholder="Confirm new password"
                  type="password"
                  onChange={handleFormChange}
                  validate={resetPasswordValidator.confirmPassword}
                />
              </div>

              <button className="settings-bth" type="submit">
                Change password
              </button>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = ({ UpdatePassword: { changeSuccessMsg } }) => ({
  changeSuccessMsg,
});

export default connect(mapStateToProps, { userUpdatePassword })(SettingsPage);
