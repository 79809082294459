import React from "react";
import { Link } from "react-router-dom";

import logOut from "../../../assets/images/log-out.svg";
import "./header.scss";

const Header = () => (
  <div className="header">
    <Link to="/employment-forms" className="title">
      CertiHire
    </Link>
    <Link to="/logout" className="logout">
      <img src={logOut} alt="logout" />
      <span>Log out</span>
    </Link>
  </div>
);

export default Header;
