import React from 'react'

export default function CustomCheckbox({
                                        value,
                                        handleBlur,
                                        onChangeFieldStatus=()=>{},
                                        name,
                                        type,
                                           isChecked,
                                        isDisabled=false,
                                        id,
                                        ...other
                                    }) {
    const handleChange = ({target: {name, checked}}) => {
        onChangeFieldStatus(name, checked)
    }
    return (

        <input
            {...other}
            value={value}
            name={name}
            id={id}
            disabled={isDisabled}
            onChange={handleChange}
            checked={isChecked}
            type={type}
        />
    );
}