export const MAX_LENGTH = 50;
export const MAX_EMAIL_LENGTH = 129;
export const MIN_FIRST_NAME_LENGTH = 3;
export const MIN_LAST_NAME_LENGTH = 3;
export const MIN_COMPANY_NAME_LENGTH = 3;
export const MIN_PHONE_NUMBER_LENGTH = 10;
// export const PHONE_NUMBER_PATTERN = /^[+]*[1]*[ ][(]?[0-9]{3}[)]?[-\s\./0-9]*$/
export const PHONE_NUMBER_PATTERN = /^([-\s\./0-9()+]{0,17})$/
export const REPLACE_NUMBER_PATTERN = /[\+\D]/g
export const EMAIL_PATTERN = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64})@([a-zA-Z]{2,55}\.[a-zA-Z]{2,8})$/;


// ERRORS MASSAGE
export const FIRST_NAME_VALID_ERROR = 'Must be from 3 to 50 symbols';
export const LAST_NAME_VALID_ERROR = 'Must be from 3 to 50 symbols';
export const COMPANY_NAME_VALID_ERROR = 'Company name must contain at least 3 characters!';
export const EMAIL_VALID_ERROR = 'Email is not in valid format!';
export const PHONE_NUMBER_VALID_ERROR = 'Phone number is invalid!';

export const FIRST_NAME_REQUIRED_ERROR = 'First name is required!';
export const LAST_NAME_REQUIRED_ERROR = 'Last name is required!';
export const COMPANY_NAME_REQUIRED_ERROR = 'Company name is required!';
export const EMAIL_REQUIRED_ERROR = 'Email is required!';
export const PHONE_NUMBER_REQUIRED_ERROR = 'Phone number is required!';

export const SPACE_ONLY_ERROR = "Can’t contain spaces only."

export const phoneNumberMask = [
    "+",
    /\d/,
    " ",
    "(",
    /\d/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];