import React, { useCallback, useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { useField } from 'formik';
import "./GoogleAutocomplete.scss"

const UK = "GB";

const searchOptionsCountry = {
    componentRestrictions: {

    },
};

const GoogleAutocomplete = ({ name, type = "text", onSelect, placeholder, placeId, defaultValue, allowClear = true, label, maxLength }) => {
    const [isOpenAutocomplete, updateIsOpenAutocomplete] = useState(false);
    const [locationAddress, setLocationAddress] = useState('');
    const [, {error, touched}, {setTouched}] = useField({ name, type, placeholder });


    const onUpdate = (address, placeId) => {
        geocodeByPlaceId(placeId)
            .then(results => {
                const [{ geometry: { location } }] = results;
                onSelect({ address, placeId, latitude: location.lat(), longitude: location.lng() })
            })
    };

    const handleSelect = (addressName, placeId) => {
        updateIsOpenAutocomplete(false);
        onUpdate(addressName, placeId);
    };

    const handleChange = (address) => {
        updateIsOpenAutocomplete(true);
        setLocationAddress(address.trimLeft());
    };

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    const getLocationName = useCallback(() => {
        if (!placeId) {
            setLocationAddress(defaultValue);
            return;
        }

        geocodeByPlaceId(placeId)
            .then(results => {
                const [{ formatted_address }] = results;
                setLocationAddress(formatted_address);
                updateIsOpenAutocomplete(false);
            })
    }, [placeId, defaultValue]);

    // Clear selection
    const onReset = () => {
        onSelect({})
    };

    const onBlur = () => {
        if (allowClear && !locationAddress) {
            onReset();
            return
        }

        getLocationName();
    };

    // Get address name
    useEffect(() => {
        getLocationName()
    }, [getLocationName]);

    useEffect(() => {
        if (!!locationAddress && !placeId && !isOpenAutocomplete) {
            setLocationAddress("")
        }
        if (!!locationAddress && placeId && !isOpenAutocomplete) {
            getLocationName()
        }
    }, [locationAddress, placeId, isOpenAutocomplete]);

    return (
        <PlacesAutocomplete
            debounce={200}
            value={locationAddress || ""}
            onChange={(address) => handleChange(address)}
            onSelect={(address, placeId) => handleSelect(address, placeId)}
            searchOptions={searchOptionsCountry}
            onError={onError}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                const inputProps = getInputProps({
                    onBlur,
                    name,
                    type,
                    placeholder: placeholder,
                    className: `form-control ${error && touched ? 'c-invalid-input' : ''}`,
                });
                return (
                    <div className="autocomplete-wrapper">
                        <label>{label}</label>
                        <input
                            {...inputProps}
                            className={error && touched ? "form-control is-invalid" : "form-control"}
                            onBlur={(event) => {
                                event.preventDefault()
                                updateIsOpenAutocomplete(false);
                                setTouched(true)
                                onBlur()
                            }}
                            maxLength={maxLength}
                            autoComplete="nope"
                        />
                        <span className="invalid-feedback">Address is a required field</span>
                        {
                            isOpenAutocomplete && (loading || !!suggestions.length) &&
                            <div className="autocomplete-dropdown-container">

                                {
                                    loading &&
                                    <div className={"autocomplete-dropdown__loading"}>Loading...</div>
                                }

                                {
                                    suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                            ? 'suggestion__item--active'
                                            : 'suggestion__item';
                                        return (
                                            <div

                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                })}
                                                key={`ofghare${index}`}
                                            >
                                                <span className="suggestion__text">{suggestion.description}</span>

                                            </div>
                                        );
                                    })
                                }
                            </div>}
                    </div>
                )
            }}
        </PlacesAutocomplete>
    )
};

export default GoogleAutocomplete;