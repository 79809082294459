import React from 'react';
import { useField } from 'formik';
import CustomCheckbox from './Checkbox';

import "./FormikCheckbox.scss"

const FormikCheckbox = ({
                         placeholder,
                         name,
                         type,
                         label,
                          onChangeFieldStatus,
                         isDisabled,
                         id,
                         isChecked
                     }) => {
    const [field, meta] = useField({ name, type, placeholder });
    return (

            <label className="container-check">{label}
            <CustomCheckbox
                {...field}
                onChangeFieldStatus={onChangeFieldStatus}
                isDisabled={isDisabled}
                isChecked={isChecked}
                type="checkbox"
            />
            <span className="checkmark"></span>
            </label>

    )
};


export default FormikCheckbox;