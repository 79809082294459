import * as yup from "yup";
import {JOB_ADDRESS, JOB_DESCRIPTION, JOB_POSITION, JOB_CERTIFICATES, JOB_ZIP_CODE, JOB_CITY, JOB_UNION} from "./job";
import {
    DESCRIPTION_REQUIRED_ERROR,
    POSITION_REQUIRED_ERROR,
    UNION_REQUIRED_ERROR
} from "../../constants/job-creation-constants"

export const validationSchema = yup.object().shape({
    location: JOB_ADDRESS,
    positionId: JOB_POSITION.required(POSITION_REQUIRED_ERROR),
    isUnion: JOB_UNION.required(UNION_REQUIRED_ERROR),
    certificatesTypes: JOB_CERTIFICATES,
    zipCode: JOB_ZIP_CODE,
    city: JOB_CITY,
    description: JOB_DESCRIPTION.required(DESCRIPTION_REQUIRED_ERROR),
});

export const initialValues = {
    location: "",
    positionId: "",
    isUnion: "",
    certificatesTypes: [],
    zipCode: "",
    city: "",
    description: "",
};
