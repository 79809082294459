import React, {useRef} from "react";
import {ReactComponent as Filter} from '../../assets/images/ic-filter.svg'
import {ReactComponent as Close} from '../../assets/images/close_icon.svg'
import "./SearchControlsContainer.scss"

const SearchControlsContainer = ({onSetSearch, onSubmit, Search}) => {
    const ref = useRef(null);

    const handleSearchChange = (e) => {
        onSetSearch(e.target.value)
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }

    const handleClick = () => {
        onSetSearch('')

        requestAnimationFrame(() => {
            ref.current.click()
        })
    }

    return(
        <form  className="controls-container" onSubmit={handleSubmit}>
            <div className="search-container">
                <input value={Search} onChange={handleSearchChange} placeholder="Search ..."/>
                {Search &&
                <button type="button" onClick={handleClick} className="search-cv-reset">
                    <Close
                    className="search-cv-close-icon"/></button>
                }
                <button type="submit" className="search-cv-submit" ref={ref}>Search</button>
            </div>
        </form>
    )
}

export default SearchControlsContainer;