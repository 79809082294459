import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import {getEmploymentFormPdf} from "../../store/actions";

import "./EmploymentForms.scss";
import Pagination from "../../components/Pagination/TablePagination";
import {EMPLOYMENT_FORM_LIMIT} from "../../constants/pagination";
import employmentsFormService from "../../services/EmploymentFormService";
import Table from "../../components/Tabel/Table";
import {TableStructure} from "../../components/TableStructures/employmentsFormsTableStructure";
import Loader from "../../components/Loader";

const breadcrumbs = [
    {
        linkTo: "/employment-forms",
        title: "Home",
    },
    {
        linkTo: "/employment-forms",
        title: "Employment Applications",
    },
];

const EmploymentForms = ({
                             getEmploymentFormPdf,
                         }) => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [offset, setOffset] = useState(0)
    const [sortingValue, setSortingValue] = useState(null)

    const isNotEmpty = items.length

    useEffect(() => {
      if(isNotEmpty) {
        document.querySelector('.form-control').placeholder = 'Search …';
      }
    }, []);

    const handleGetEmployments = () => {
        const orderKey = sortingValue?.column === "date" ? "DateTime" : sortingValue?.column
        employmentsFormService.getEmploymentForms(offset, orderKey, sortingValue?.direction)
            .then((response) => {
                setTotalCount(response.pagination.totalCount)
                setItems(response.data)
            })
            .finally(() => {
                setShowEmptyMessage(true)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setIsLoading(true)
        handleGetEmployments();
    }, [offset, sortingValue]);

    const updateOffset = (newOffset) => {
        setOffset(newOffset)
    }

    const getEmploymentFromPdF = ({employmentsId: id, email}, needDownload = false) => {
        const params = {id, email};
        if (needDownload) Object.assign(params, {needDownload});
        return () => getEmploymentFormPdf(params);
    };

    const columns = TableStructure(sortingValue, setSortingValue, getEmploymentFromPdF)

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            <Card className="employment-forms-card">
                <CardBody>
                    <div className="employment-forms-title">Employment Applications</div>
                    {isNotEmpty ?
                        <>
                            <Table
                                columns={columns}
                                data={items}
                                classes={"employment-table-container"}
                            />

                            <Pagination limit={EMPLOYMENT_FORM_LIMIT}
                                        offset={offset || 0}
                                        totalCount={totalCount}
                                        onUpdateOffset={updateOffset}/>
                        </> :
                        <div className="jobs-empty-container">
                            {isLoading && <Loader/> }
                            {showEmptyMessage && <div className="jobs-empty-content">There are no employment applications yet</div>}

                        </div>
                    }
                </CardBody>
            </Card>
        </>
    );
};

export default connect(null, {
    getEmploymentFormPdf,
})(EmploymentForms);
