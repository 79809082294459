import React from 'react';
import { Modal} from 'reactstrap';
import {ReactComponent as Close} from '../../assets/images/close_icon.svg'
import "./Modal.scss"


const ModalExample = (props) => {
    const {
        title,
        text,
        onToggle,
        container,
        isModal,
    } = props;

    return (
        <div >
            <Modal isOpen={isModal} toggle={onToggle} className='modal-container'>
                <button className="close-modal-button" onClick={onToggle}>
                    <Close className="close-modal-icon"/>
                </button>

                <h3>{title}</h3>
                <div className="modal-text">
                    {text}
                </div>
                <div className="buttons-container">
                    {container}

                </div>
            </Modal>
        </div>
    );
}

export default ModalExample;