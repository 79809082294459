import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom"
import {Card, CardBody} from "reactstrap";

import Breadcrumb from "../../components/Common/breadcrumb";
import JobListNavbar from "../../components/JobList/JobListNavbar";
import Pagination from "../../components/Pagination/TablePagination";
import ModalExample from "../../components/Modal/Modal";
import DeleteModalButtons from "../../components/Modal/ModalButtonContainers/DeleteModalButtons";
import Table from "../../components/Tabel/Table";
import {TableStructure} from "../../components/TableStructures/positionsTableStructures";

import positionsService from "../../services/PositionsService";

import {
    DELETE_POSITION_MODAL_TEXT,
    DELETE_POSITION_MODAL_TITLE,
} from "../../constants/positions-constants.js";
import {JOBS_LIMIT} from "../../constants/pagination";

import "../JobList/JobList.scss"
import Loader from "../../components/Loader";


const breadcrumbs = [
    {
        linkTo: "/job-listing/list-of-jobs",
        title: "",
        pageTitle: "Job Listing"
    },
];

const PositionsList = () => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)
    const [totalCount, setTotalCount] = useState([])
    const [offset, setOffset] = useState(0)
    const [isModal, setIsModal] = useState(false)
    const [sortingValue, setSortingValue] = useState(null)
    const [idForDeletePosition, setIdForDeletePosition] = useState('')

    const updateOffset = (newOffset) => {
        setOffset(newOffset)
    }

    const toggle = () => {
        setIsModal(!isModal)
    }

    const onOpenModalWithId = (id) => {
        setIdForDeletePosition(id)
        toggle()
    }

    const isNotEmpty = items.length

    const handleGetPositions = () => {
        positionsService.getPositions(offset, sortingValue?.column, sortingValue?.direction)
            .then((response) => {
                setTotalCount(response.pagination.totalCount)
                setItems(
                    response.data.map(item => {
                        return {
                            id: item.id,
                            name: item.name,
                            createdAt: item.createdAt,
                        }
                    })
                )
            })
            .finally(() => {
                setShowEmptyMessage(true)
                setIsLoading(false)
            })
    }


    useEffect(() => {
        setIsLoading(true)
        handleGetPositions()
    }, [offset, sortingValue]);

    const handleDeletePosition = () => {
        positionsService.deletePosition(idForDeletePosition)
            .then(() => {
                toggle()
                handleGetPositions()
            })
    }

    const columns = TableStructure(sortingValue, setSortingValue, onOpenModalWithId)

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} field={"pageTitle"}/>
            <JobListNavbar/>
            <Card className="job-list-forms-card">
                <CardBody>
                    <div className="job-list-forms-title">
                        <div>Positions/Titles</div>
                        <Link className="job-list-create-link" to="/job-listing/position-creation">Create</Link>
                    </div>

                    {isNotEmpty ?
                        <>
                            <Table
                                columns={columns}
                                data={items}
                            />

                            <Pagination limit={JOBS_LIMIT}
                                        offset={offset || 0}
                                        totalCount={totalCount}
                                        onUpdateOffset={updateOffset}/>
                        </> :
                        <div className="jobs-empty-container">
                            {isLoading && <Loader/> }
                            {showEmptyMessage && <div className="jobs-empty-content">There are no Positions/Titles created yet.</div>}
                        </div>

                    }
                    <ModalExample
                        onToggle={toggle}
                        container={<DeleteModalButtons
                            onToggle={toggle}
                            onDelete={handleDeletePosition}
                        />
                        }

                        isModal={isModal}
                        title={DELETE_POSITION_MODAL_TITLE}
                        text={DELETE_POSITION_MODAL_TEXT}
                    />

                </CardBody>
            </Card>
        </>
    );
};

export default PositionsList;
