import {
  MIN_LENGTH,
  MIN_LENGTH_ERROR,
  MAX_LENGTH,
  MAX_LENGTH_ERROR,
  PASSWORD_VALIDATION,
  PASSWORD_VALIDATION_ERROR,
  PASSWORD_MATCH_ERROR,
  PASSWORD_REQUIRED,
} from "../constants/form-constants";

const getValidatePassword = (_, { password, confirmPassword }, input, cb) => {
  password === confirmPassword ? cb(true) : cb(PASSWORD_MATCH_ERROR);
};

export default {
  password: {
    required: { value: true, errorMessage: PASSWORD_REQUIRED },
    minLength: { value: MIN_LENGTH, errorMessage: MIN_LENGTH_ERROR },
    maxLength: { value: MAX_LENGTH, errorMessage: MAX_LENGTH_ERROR },
    pattern: {
      value: PASSWORD_VALIDATION,
      errorMessage: PASSWORD_VALIDATION_ERROR,
    },
  },
  confirmPassword: {
    minLength: { value: MIN_LENGTH, errorMessage: MIN_LENGTH_ERROR },
    maxLength: { value: MAX_LENGTH, errorMessage: MAX_LENGTH_ERROR },
    async: getValidatePassword,
  },
};
