import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
} from "./actionTypes";

export const userUpdatePassword = (data, history) => {
  return {
    type: UPDATE_PASSWORD,
    payload: { data , history }
  };
};

export const userUpdatePasswordSuccess = message => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: message
  };
};

export const userUpdatePasswordError = message => {
  return {
    type: UPDATE_PASSWORD_ERROR,
    payload: message
  };
};
