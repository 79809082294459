import Http from "./HttpService";


class PositionsService extends Http {

    createPosition (model) {
        return this.post("admin-positions", model)
    }

    getPositions(Offset= 0, Key, Direction, Limit=10,) {
        return this.get(`admin-positions`, {params: {Offset, Limit, "Order.Key": Key, "Order.Direction": Direction}  })
    }
    getPositionById(id) {
        return this.get(`admin-positions/${id}`)
    }
    deletePosition(id) {
        return this.delete(`admin-positions/${id}`)
    }
    editPosition(id, model) {
        return this.put(`admin-positions/${id}`, model)
    }
}


export default new PositionsService()