import React, { useEffect } from "react";
import {Container} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import QueryHelper from "../../helpers/query-params-helper";
import { userResetPassword, isValidToken } from "../../store/actions";
import {replaceSpaceOnPlus} from "../../helpers/replaceSpaceOnPlus";
import "./login.scss";
import {validationSchema} from "../../validators/changePassword/form";
import FormikInput from "../../components/FormikComponents/FormikInput";
import {Formik} from "formik";


const ChangePasswordPage = ({ userResetPassword, isValidToken, history }) => {
  const { email, token } = QueryHelper(["email", "token"]);

  useEffect(() => {
    isValidToken({ email: replaceSpaceOnPlus(email), token }, history);
  }, [token]);

  const handleValidSubmit = ({ password, confirmPassword }) => {
    userResetPassword({ email: replaceSpaceOnPlus(email), token, password, confirmPassword }, history);
  };

  return (
    <Container className="login-container">
      <div className="login-wr">
        <div className="login-head">
          <Link to="/" className="title">
            CertiHire
          </Link>
          <div className="subtitle">Welcome to CertiHire</div>
          <div className="text">Restore password</div>
        </div>
        <div>
          <Formik
              initialValues={{password: "", confirmPassword: ""}}
              validationSchema={validationSchema}
              onSubmit={handleValidSubmit}
              validateOnMount
          >
            {({errors, touched, handleSubmit, setFieldValue, values, isValid, ...p}) => {

              return (

                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <div >
                      <FormikInput
                          name="password"
                          label="New Password"
                          className="form-control"
                          placeholder="Enter new password"
                          type="password"
                          id="password"
                          containerClassName="creation-job-card-group"
                      />

                      <FormikInput
                          id="confirmPassword"
                          containerClassName="creation-job-card-group"
                          name="confirmPassword"
                          label="Confirm New Password"
                          className="form-control"
                          placeholder="Confirm new password"
                          type="password"
                      />

                    </div>
                    <button className="login-bth" type="submit" disabled={!(isValid)}>
                      Create a new password
                    </button>

                  </form>
              )
            }}
          </Formik>

        </div>
      </div>
    </Container>
  );
};

export default withRouter(
  connect(null, { userResetPassword, isValidToken })(ChangePasswordPage),
);
