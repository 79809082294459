import React, {useEffect, useMemo, useState} from "react";

import {Link} from "react-router-dom"

import {Card, CardBody} from "reactstrap";
import {MDBDataTable} from "mdbreact";
import Breadcrumb from "../../components/Common/breadcrumb";
import Pagination from "../../components/Pagination/TablePagination"
import {
    TABLE_ENTRIES,
    TABLE_DISPLAY_ENTRIES,
    TABLE_INFO,
    TABLE_PAGING,
    TABLE_PAGE_AMOUNT,
    TABLE_SORTABLE
} from "../../constants/table-constant";
import {JOBS_LIMIT} from "../../constants/pagination";
import employeesService from "../../services/EmployeesManagementService"
import {
    DELETE_EMPLOYEE_MODAL_TEXT,
    DELETE_EMPLOYEE_MODAL_TITLE,
    EMPLOYEES_LIST_COLUMNS
} from "../../constants/employees-constants";
import EditButton from "../../components/EmpoyeesComponents/EditButton";
import DeleteButton from "../../components/EmpoyeesComponents/DeleteButton";

import "./EmployeesManagement.scss";
import "../../components/TableCellInnerContainer/index.scss"
import {formateDate, formateTime} from "../../helpers/format-time";
import DeleteModalButtons from "../../components/Modal/ModalButtonContainers/DeleteModalButtons";
import ModalExample from "../../components/Modal/Modal";
import TableCellInnerContainer from "../../components/TableCellInnerContainer";
import Loader from "../../components/Loader";

const breadcrumbs = [
    {
        linkTo: "/employees-management/list-of-employees",
        title: "Employees Management",
    },
]


const EmployeesManagement = () => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)
    const [totalCount, setTotalCount] = useState([])
    const [offset, setOffset] = useState(0)
    const [isModal, setIsModal] = useState(false);
    const [idForDeleteEmployee, setIdForDeleteEmployee] = useState('')

    const updateOffset = (newOffset) => {
        setOffset(newOffset)
    }

    const toggle = () => setIsModal(!isModal);
    const onOpenModalWithId = (id) => {
        setIdForDeleteEmployee(id)
        toggle()
    }
    const isNotEmpty = items.length

    const handleGetEmployees = () => {
        employeesService.getEmployees(offset)
            .then((response) => {
                setTotalCount(response.pagination.totalCount)
                setItems(
                    response.data.map(item => {
                        return {
                            id: item?.id,
                            firstName: item?.personalInfo?.firstName,
                            lastName: item?.personalInfo?.lastName,
                            phoneNumber: item?.phoneNumber,
                            registartedAt: item?.registartedAt || null,
                            dateOfUpload: item?.registartedAt ? formateDate(item?.registartedAt) : null,
                            timeOfUpload: item?.registartedAt ? formateTime(item?.registartedAt).toLowerCase() : null,
                            email: item?.email,
                        }
                    })
                )
            })
            .finally(() => {
                setShowEmptyMessage(true)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setIsLoading(true)
        handleGetEmployees()
    }, [offset]);

    const handleDeleteEmployee = () => {
        employeesService.deleteEmployee(idForDeleteEmployee)
            .then(() => {
                toggle()
                handleGetEmployees()
            })
    }
    const itemsToData = (items) => {
        return items.map((item) => {
            item.firstName = (
                <Link className="employees-list-forms-tabel-field" to={`/employees-management/employee-info/${item.id}`}>
                    <TableCellInnerContainer content={item?.firstName}/>
                </Link>
            )
            item.lastName = (
                <Link className="employees-list-forms-tabel-field" to={`/employees-management/employee-info/${item.id}`}>
                    <TableCellInnerContainer content={item?.lastName}/>
                </Link>            )
            item.phoneNumber = (
                <Link className="employees-list-forms-tabel-field" to={`/employees-management/employee-info/${item.id}`}>
                    <TableCellInnerContainer content={item?.phoneNumber}/>
                </Link>            )
            item.email = (
                <Link className="employees-list-forms-tabel-field" to={`/employees-management/employee-info/${item.id}`}>
                    <TableCellInnerContainer content={item?.email}/>
                </Link>            )
            item.date = (

                item.dateOfUpload ?
                    <Link className="employees-list-forms-tabel-field" to={`/employees-management/employee-info/${item.id}`}>
                        <span>{item.dateOfUpload} </span>
                        <span>{item.timeOfUpload}</span>
                    </Link> :
                    <Link className="employees-list-forms-tabel-field" to={`/employees-management/employee-info/${item.id}`}>
                        <span className={"td-inner-empty"}>-</span>
                    </Link>
            )

            item.action = (
                <>
                    <EditButton path={`/employees-management/edit-employee/${item?.id}`} id={item?.id}/>
                    <DeleteButton id={item?.id} onDelete={onOpenModalWithId}/>
                </>
            )
            return item;
        });
    };


    const data = useMemo(() => {
        return {
            columns: EMPLOYEES_LIST_COLUMNS,
            rows: itemsToData(items),
        };
    }, [items]);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            <Card className="employees-list-forms-card">
                <CardBody>
                    <div className="employees-list-forms-title">
                        <div>Employees Management</div>
                        <Link className="employees-list-create-link"
                              to="/employees-management/employee-creation">Add</Link>
                    </div>
                    {isNotEmpty ?
                        <>
                            <MDBDataTable
                                responsive
                                striped
                                bordered
                                noBottomColumns
                                data={data}
                                displayEntries={TABLE_DISPLAY_ENTRIES}
                                info={TABLE_INFO}
                                paging={TABLE_PAGING}
                                entries={TABLE_ENTRIES}
                                pagesAmount={TABLE_PAGE_AMOUNT}
                                sortable={TABLE_SORTABLE}

                                className="employees-list-forms-tabel"
                            />
                            <Pagination limit={JOBS_LIMIT}
                                        offset={offset || 0}
                                        totalCount={totalCount}
                                        onUpdateOffset={updateOffset}/>
                        </> :
                        <div className="employees-empty-container">
                            {isLoading && <Loader/> }
                            {showEmptyMessage && <div className="employees-empty-content">There are no Employees created yet.</div>}

                        </div>
                    }
                </CardBody>
                <ModalExample
                    onToggle={toggle}
                    isModal={isModal}
                    title={DELETE_EMPLOYEE_MODAL_TITLE}
                    text={DELETE_EMPLOYEE_MODAL_TEXT}
                    container={
                        <DeleteModalButtons
                            onToggle={toggle}
                            onDelete={handleDeleteEmployee}
                        />
                    }
                />
            </Card>
        </>
    );
};

export default EmployeesManagement;
