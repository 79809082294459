import Http from "./HttpService";
import moment from "moment";

class EmploymentFormService extends Http {
  apiUrl = "admin-employments";
  getPdf(employmentsId) {
    return this.get(`${this.apiUrl}/${employmentsId}`, {
      responseType: "blob",
    });
  }

  getEmploymentForms(Offset, Key, Direction, Limit=10) {
    return this.get(this.apiUrl, {params: {
        Offset,
        "Order.Key": Key,
        "Order.Direction": Direction,
        Limit
    }
    }).then((response) => {
      response.data.map((item) => {
        const date = item.date;
        item.date = moment(date).format("MM/DD/YYYY");
        item.time = moment(date).format("LT");
        return item;
      });
      return response

    });
  }
}

export default new EmploymentFormService();
