import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  IS_VALID_TOKEN,
} from "./actionTypes";

const initialState = {
  changeSuccessMsg: null,
  changeError: null
};

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      state = {
        ...state,
        changeSuccessMsg: null,
        changeError: null
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        changeSuccessMsg: action.payload
      };
      break;
    case RESET_PASSWORD_ERROR:
      state = { 
        ...state, 
        changeError: action.payload 
      };
      break;
    case IS_VALID_TOKEN: 
      state = {
        ...state
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default changePassword;
  