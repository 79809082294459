import React, {useState, useEffect} from "react";
import { Card, CardBody } from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";

import "./EmployeeHandbooks.scss"
import {EnglishHandbookDropzone} from "../../components/HandbooksDropzone/EnglishHandbookDropzone";
import {SpanishHandbookDropzone} from "../../components/HandbooksDropzone/SpanishHandbookDropzone";
import handbookService from "../../services/HandbookService";
import Loader from "../../components/Loader";

const breadcrumbs = [
    {
        linkTo: "/employee-handbooks",
        title: "Employee Handbooks",
    },
];

const EmployeeHandbooks = () => {
    const [ englishHandbook, setEnglishHandbook] = useState(null)
    const [ spanishHandbook, setSpanishHandbook] = useState(null)
    const [isDisabledEnglish, setIsDisabledEnglish] = useState(false)
    const [isDisabledSpanish, setIsDisabledSpanish] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)

    let map = {}

    const separateHandbooksByLanguage = (data) => {
        map = {}
        data.forEach((item) => {
            map[item.language] = item;
        })

        setEnglishHandbook(map.English)
        setSpanishHandbook(map.Spanish)
    }

    const handleGetHandbooks = () => {
        handbookService.getHandbook()
            .then(response => {
                separateHandbooksByLanguage(response.data)
            })
            .finally(() => {
                setShowEmptyMessage(true)
                setIsLoading(false)
                setIsLoaded(true)
            })
    }

    useEffect(()=>{
        setIsLoading(true)
        handleGetHandbooks()

    }, [])

    const handleDeleteHandbook = (id) => {
        handbookService.deleteHandbook(id)
            .then(()=>{
                handleGetHandbooks()
                setIsDisabledEnglish(false)
                setIsDisabledSpanish(false)
            })
            .catch(()=> {
                setIsDisabledEnglish(true)
                setIsDisabledSpanish(true)
            })
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Card className="handbooks-card">
                <CardBody className="handbooks-card-body">
                    <div className="handbooks-card-title">Upload Employee Handbook</div>

                    <EnglishHandbookDropzone
                        handbook={englishHandbook}
                        isDisabled={isDisabledEnglish || !isLoaded}
                        onSetIsDisabled={setIsDisabledEnglish}
                        onSetHandbook={setEnglishHandbook}
                        onDeleteHandbook={handleDeleteHandbook}
                    />
                    <SpanishHandbookDropzone
                        handbook={spanishHandbook}
                        isDisabled={isDisabledSpanish || !isLoaded}
                        onSetIsDisabled={setIsDisabledSpanish}
                        onSetHandbook={setSpanishHandbook}
                        onDeleteHandbook={handleDeleteHandbook}
                    />
                    {isLoading && <div className="handbook-empty-container"><Loader/> </div>}
                    {showEmptyMessage && !englishHandbook && !spanishHandbook && <div className="handbook-empty-container">There are no uploaded handbooks yet</div>}
                </CardBody>
            </Card>
        </>
    );
};

export default EmployeeHandbooks