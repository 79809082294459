import React from "react";
import {Link, useHistory} from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import "./breadcrumb.scss";

const Breadcrumb = ({ breadcrumbs, field="title", status, toggle }) => {
    const history = useHistory()

    const handleCancelButtonClick = (link, status) => {
        if (status) {
            toggle()
        } else {
            history.push(link)
        }
    }
   return (
       <div className="breadcrumbs">
        <div className="title">{breadcrumbs[breadcrumbs.length - 1][field]}</div>
        <div className="d-flex align-items-center breadcrumbs-wr">
            {breadcrumbs.map((breadcrumb, index) => {
                if(index === breadcrumbs.length - 1){
                    return <BreadcrumbItem key={`breadcrumb-${index}`}>
                        <Link to={breadcrumb.linkTo}>{breadcrumb.title}</Link>
                    </BreadcrumbItem>
                } else {
                    return <BreadcrumbItem key={`breadcrumb-${index}`}>
                        <Link to={"#"}
                              onClick={() => handleCancelButtonClick(breadcrumb.linkTo, status)}>{breadcrumb.title}</Link>
                    </BreadcrumbItem>
                }

            })}
        </div>
    </div>
   )
};

export default Breadcrumb;
