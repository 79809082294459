import React, {useEffect, useMemo, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";

// formik-validation
import {Formik} from "formik";
import {validationSchema} from "../../validators/creationEmployeeFormValidation/form";
import {MAX_LENGTH} from "../../constants/job-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";

import employeesService from "../../services/EmployeesManagementService"

import {MAX_EMAIL_LENGTH, REPLACE_NUMBER_PATTERN} from "../../constants/employer-creation-constants";
import FormikInputPhone from "../../components/FormikComponents/FormikInputPhone";
import ModalExample from "../../components/Modal/Modal";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import "./ EmployeeEdit.scss"
import {EDIT_EMPLOYEE_MODAL_TEXT, EDIT_EMPLOYEE_MODAL_TITLE} from "../../constants/employees-constants";
import ButtonsContainer from "../../components/ButtonsContainer/ButtonsContainer";


const EmployeeEdit = () => {
    const [item, setItem] = useState({})
    const [isDirty, setIsDirty] = useState(false)
    const [isModal, setIsModal] = useState(false)

    const history = useHistory()
    const params = useParams()

    const breadcrumbs = useMemo(() => ([
        {
            linkTo: "/employees-management/list-of-employees",
            title: "Employees Management",
        },
        {
            linkTo: `/employees-management/edit-employee/${params.id}`,
            title: "Employee Edit",
        },
    ]), [params.id])

    useEffect(() => {
        employeesService.getEmployeeById(params.id)
            .then((response) => {
                setItem(response.data)
            })
    }, [])

    const initialValue = {
        firstName: item?.personalInfo?.firstName,
        lastName: item?.personalInfo?.lastName,
        email: item?.email,
        phoneNumber: item?.phoneNumber?.slice(1),
    }

    const handleEditEmployee = ({
                                    firstName,
                                    lastName,
                                    companyName,
                                    email,
   phoneNumber,
                                }) => {


        const data = {
            firstName,
            lastName,
            companyName,
            email,
            phoneNumber: "+" + phoneNumber.replace(REPLACE_NUMBER_PATTERN, ""),
        };
        employeesService.editEmployee(item.id, data)
            .then(() => history.push(`/employees-management/list-of-employees`))
    }

    const toggle = () => setIsModal(!isModal);

    const handleCancelButtonClick = (status) => {
        if (status) {
            toggle()
        } else {
            history.push(`/employees-management/list-of-employees`)
        }
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle}/>
            {item.hasOwnProperty("phoneNumber") &&
            <Card className="creation-job-card">
                <CardBody className="creation-job-card">
                    <div className="creation-job-title">Employee Edit</div>
                    <Formik
                        initialValues={initialValue}
                        validationSchema={validationSchema}
                        onSubmit={handleEditEmployee}
                        validateOnMount
                    >
                        {({
                              errors, touched, handleSubmit, setFieldValue, dirty, values, isValid, handleChange,
                              handleBlur, ...p
                          }) => {
                            setIsDirty(dirty)

                            return (

                                <form className="form-horizontal  creation-employer-form" onSubmit={handleSubmit}>
                                    <div className="creation-job-form-group">

                                        <FormikInput
                                            id="firstName"
                                            label="First Name"
                                            name="firstName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            placeholder="Input First Name"
                                            maxLength={MAX_LENGTH}
                                        />


                                        <FormikInput
                                            id="lastName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            name="lastName"
                                            label="Last Name"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Last Name"
                                            maxLength={MAX_LENGTH}
                                        />

                                        <FormikInput
                                            name="email"
                                            label="Email"
                                            type="email"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Email"
                                            id="email"
                                            containerClassName="creation-employer-card-group"
                                            maxLength={MAX_EMAIL_LENGTH}
                                        />
                                        <FormikInputPhone
                                            name="phoneNumber"
                                            label="Phone Number"
                                            type="tel"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Phone Number"
                                            id="phoneNumber"
                                            containerClassName="creation-employer-card-group"
                                        />
                                    </div>
                                    <ButtonsContainer
                                        isDisabled={!(isValid)}
                                        onCancelButtonClick={() => handleCancelButtonClick(isDirty)}
                                        submitButtonContent="Save"
                                    />
                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            id={item.id}
                                            onToggle={toggle}
                                            path={`/employees-management/list-of-employees`}
                                        />}

                                        isModal={isModal}
                                        title={EDIT_EMPLOYEE_MODAL_TITLE}
                                        text={EDIT_EMPLOYEE_MODAL_TEXT}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
            }
        </>
    );


};


export default EmployeeEdit;
