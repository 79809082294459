import React from "react";
import {ReactComponent as Edit} from '../../assets/images/edit-icon.svg'
import { Link} from "react-router-dom";

const EditButton = ({id, path}) => {
    return (
        <Link  to={path} id={id} className="actions-control actions-edit" ><Edit/></Link>
    )
};

export default EditButton;
