import JobListActions from "../JobList/JobListActions";
import React from "react";
import moment from "moment";
import {DATE_FORMAT} from "../../constants/positions-constants";
import {ASC_LABEL, DESC_LABEL} from "../../constants/table-constant";
import {createColumn} from "../../helpers/createColumn";

export const TableStructure = (sortingValue, onSetSortingValue, onOpenModalWithId) => {
    const handleClick = (dataField) => {
        onSetSortingValue({column: dataField, direction: sortingValue?.direction === ASC_LABEL ? DESC_LABEL : ASC_LABEL})
    }
    return [
        createColumn(
            {
                dataField: 'name',
                text: 'Name',
                width: "69%",
                isSortable: true
            },
            handleClick,
            sortingValue),
        {
            ...createColumn(
                {
                    text: "Date of Creation",
                    dataField: "createdAt",
                    width: "15.5%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (data) => moment(data).format(DATE_FORMAT)
        },
        {
            ...createColumn(
                {
                    dataField: 'id',
                    text: 'Actions',
                    width: "15.5%",
                },
                handleClick,
                sortingValue),
            formatter: (_, {id}) => <JobListActions id={id} onClick={onOpenModalWithId} path={`/job-listing/position-edit/${id}`}/>
        },
    ];
}