import React from "react";
import "./AcrtionButtons.scss"

const DeclineButton = ({id, onDeleteJobApplication}) => {
    const handleClick = () => {
        onDeleteJobApplication(id)
    }
    return(
        <button className="search-cv-button search-cv-button-decline" onClick={handleClick} id={id}>Decline</button>
    )
}

export default DeclineButton;