import Http from "./HttpService";


class SearchCVServices extends Http {

    getCVs ( {Limit=10, Offset, Search, OrderKey, OrderDirection}) {

        return this.get(`admin-jobs/applications`,  {params: {
                Limit,
                Offset,
                ...Search && {"Search": Search},
                "Order.Key": OrderKey,
                "Order.Direction": OrderDirection
            }})
    }

    acceptJobApplication(id) {
        return this.post(`admin-jobs/applications/${id}`)
    }

    getPDF(id) {
        return this.get(`admin-jobs/applications/file/${id}`, {
            responseType: "blob",
        })
    }

    deleteJobApplication(id) {
        return this.delete(`admin-jobs/applications/${id}`)
    }

    deleteAdminUser(id) {
        return this.put(`admin-jobs/applications/${id}/switch-off`)
    }
}


export default new SearchCVServices()