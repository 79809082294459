import { useRef, useState } from "react";

const useAvForm = () => {
  const formRef = useRef(null);
  const [hasFormError, updateErrorState] = useState(true);

  const handleFormChange = () => {
      const { invalidInputs } = formRef.current.state;
      updateErrorState(!!Object.keys(invalidInputs).length);
  };

  return [formRef, hasFormError, handleFormChange];
};

export default useAvForm;
