export  const removeEmptyFields = (data) => {
    const model = {};

    Object.keys(data).forEach(key => {
        const value = data[key];

        if (!value && value !== false)return;

        model[key] = value
    })

    return model
}