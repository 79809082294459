import React from "react";
import {ReactComponent as Delete} from "../../assets/images/delete-icon.svg";

const DeleteButton = ({id, onDelete}) => {
    return (
        <button  id={id} onClick={()=> onDelete(id)} className="actions-control actions-delete" ><Delete/></button>
    )
};

export default DeleteButton;
