import {
  GET_EMPLOYMENT,
  GET_EMPLOYMENT_SUCCESS,
  GET_EMPLOYMENT_ERROR,
  GET_EMPLOYMENT_DATA,
  GET_PDF,
} from "./actionTypes";

export const getEmploymentForms = () => {
  return {
    type: GET_EMPLOYMENT,
    payload: {},
  };
};

export const getDataSuccess = (data) => {
  return {
    type: GET_EMPLOYMENT_DATA,
    payload: data,
  };
};

export const getEmploymentFormPdf = ({id, email, needDownload }) => {
  return {
    type: GET_PDF,
    payload: { id, email, needDownload },
  };
};

export const getEmploymentFormsSuccess = (message) => {
  return {
    type: GET_EMPLOYMENT_SUCCESS,
    payload: message,
  };
};

export const getEmploymentFormsError = (message) => {
  return {
    type: GET_EMPLOYMENT_ERROR,
    payload: message,
  };
};
