import { takeEvery, fork, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import AuthService from '../../../services/AuthService';
import { toast } from "react-toastify";

const forgetUserAsync = async (email) => {
  const response = await AuthService.forgotPassword(email);
  return response.data;
};

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
      const response = yield call(forgetUserAsync, user.email);
      if (response) {
          toast.success("Reset link has been already sent. Please check your email");
          toast.clearWaitingQueue()
      }
  } catch (error) {
    toast.error(error.message);
      toast.clearWaitingQueue()
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
