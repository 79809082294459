import React, {useEffect, useMemo, useState} from "react";

import {Link } from "react-router-dom"

import { Card, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Breadcrumb from "../../components/Common/breadcrumb";
import Pagination from "../../components/Pagination/TablePagination"
import JobListStatusSwitcher from "../../components/Switcher/Switcher"

import JobListActions from "../../components/JobList/JobListActions"
import JobListNavbar from "../../components/JobList/JobListNavbar";

import {DELETE_JOB_MODAL_TEXT, DELETE_JOB_MODAL_TITLE, JOB_LIST_COLUMNS} from "../../constants/job-list-constants";
import { TABLE_ENTRIES, TABLE_DISPLAY_ENTRIES, TABLE_INFO, TABLE_PAGING, TABLE_PAGE_AMOUNT, TABLE_SORTABLE} from "../../constants/table-constant";
import { JOBS_LIMIT } from "../../constants/pagination";

import  jobsService from "../../services/JobsService"

import "./JobList.scss";
import ModalExample from "../../components/Modal/Modal";
import DeleteModalButtons from "../../components/Modal/ModalButtonContainers/DeleteModalButtons";
import TableCellInnerContainer from "../../components/TableCellInnerContainer";
import Loader from "../../components/Loader";

const breadcrumbs = [
    {
        linkTo: "/job-listing/list-of-jobs",
        title: "",
        pageTitle: "Job Listing"
    },
];


const JobList = () => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)
    const [totalCount, setTotalCount] = useState([])
    const [offset, setOffset] = useState(0)
    const [isModal, setIsModal] = useState(false)
    const [idForDeleteJob, setIdForDeleteJob] = useState('')

    const updateOffset = (newOffset) =>{
        setOffset(newOffset)
    }

    const toggle = () => {
        setIsModal(!isModal)
    }

    const onOpenModalWithId = (id) => {
        setIdForDeleteJob(id)
        toggle()
    }

    const isNotEmpty = items.length

    const handleGetJobs = () => {
        jobsService.getJobs(offset)
            .then((response) => {
                setTotalCount(response.pagination.totalCount)
                setItems(
                    response.data.map(item => {
                        return {
                            id: item.id,
                            position: item.position.name,
                            status: item.isOpen,
                        }
                    })
                )
            })
            .finally(() => {
                setShowEmptyMessage(true)
                setIsLoading(false)
            })
    }


    useEffect(() => {
        setIsLoading(true)
        handleGetJobs()
    },[offset]);

    const handleSetJobStatus = (id, status) => {
        const model = {isOpen: status}
        jobsService.setJobStatus(id, model)
    }

    const handleDeleteJob = () => {
        jobsService.deleteJob(idForDeleteJob)
            .then(() => {
                toggle()
                handleGetJobs()
            })
    }

    const itemsToData = (items) => {
        return items.map((item) => {
            item.position = (
                <TableCellInnerContainer content={item?.position}/>
            )
            item.status = (
                <JobListStatusSwitcher
                    id={item.id}
                    status={item.status}
                    onHandleSetJobStatus={handleSetJobStatus}
                />

            );
            item.action = (
                <JobListActions id={item.id} onClick={onOpenModalWithId}  path={`/job-listing/job-edit/${item.id}`}/>
            )
            return item;
        });
    };


    const data = useMemo(() => {
        return {
            columns: JOB_LIST_COLUMNS,
            rows: itemsToData(items),
        };
    }, [items]);

    return (
        <>
                     <Breadcrumb breadcrumbs={breadcrumbs}  field={"pageTitle"}/>
                     <JobListNavbar />
                     <Card className="job-list-forms-card">
                         <CardBody>
                         <div className="job-list-forms-title" >
                            <div >List of Jobs</div>
                            <Link className="job-list-create-link" to="/job-listing/job-creation">Create</Link>
                        </div>
                             {isNotEmpty ?
                             <>
                                 <MDBDataTable
                                     responsive
                                     striped
                                     bordered
                                     noBottomColumns
                                     data={data}
                                     displayEntries={TABLE_DISPLAY_ENTRIES}
                                     info={TABLE_INFO}
                                     paging={TABLE_PAGING}
                                     entries={TABLE_ENTRIES}
                                     pagesAmount={TABLE_PAGE_AMOUNT}
                                     sortable={TABLE_SORTABLE}

                                     className="job-list-forms-tabel"
                                 />
                                 <Pagination limit={JOBS_LIMIT}
                                             offset={offset || 0}
                                             totalCount={totalCount}
                                             onUpdateOffset={updateOffset}/>
                                 </> :
                                 <div className="jobs-empty-container">
                                     {isLoading && <Loader/> }
                                     {showEmptyMessage && <div className="jobs-empty-content">There are no created jobs yet</div>}

                                 </div>

                             }
                             <ModalExample
                                 onToggle={toggle}
                                 container={<DeleteModalButtons
                                     onToggle={toggle}
                                     onDelete={handleDeleteJob}
                                 />}

                                 isModal={isModal}
                                 title={DELETE_JOB_MODAL_TITLE}
                                 text={DELETE_JOB_MODAL_TEXT}
                             />
                     </CardBody>
                     </Card>
                     </>
    );
};

export default JobList;
