import React from "react";
import moment from "moment";

import {createColumn} from "../../helpers/createColumn";

import CVStatusSwitcher from "../Switcher/Switcher";
import DeclineButton from "../SearchCV/DeclineButton";

import {DATE_FORMAT} from "../../constants/positions-constants";
import {ASC_LABEL, DESC_LABEL} from "../../constants/table-constant";
import "./index.scss"
import {Link} from "react-router-dom";
import TableCellInnerContainer from "../TableCellInnerContainer";
import CVStatus from "../CVStatus/CVStatus";


export const TableStructure = (
    sortingValue,
    onSetSortingValue,
    onHandleStatus,
    onGetPdf,
    onDeleteJobApplication
) => {
    const handleClick = (dataField) => {
        if (sortingValue?.column !== dataField) {
            onSetSortingValue({column: dataField, direction: ASC_LABEL})
        } else {
            onSetSortingValue({
                column: dataField,
                direction: sortingValue?.direction === ASC_LABEL ? DESC_LABEL : ASC_LABEL
            })
        }

    }

    return [
        {
            ...createColumn(
                {
                    text: "First Name",
                    dataField: "firstName",
                    width: "11%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (_, data) => (
                <Link to={`/employees-management/employee-info/${data?.userId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.firstName}/>
                </Link> ||
                <div className={"d-flex justify-content-center "}>-</div>
            )
        },
        {
            ...createColumn(
                {
                    text: "Last Name",
                    dataField: "lastName",
                    width: "11%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (_, data) => (
                <Link to={`/employees-management/employee-info/${data?.userId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.lastName}/>
                </Link> ||
                <div className={"d-flex justify-content-center"}>-</div>
            )
        },
        {
            ...createColumn(
                {
                    text: "Email",
                    dataField: "email",
                    width: "8%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (data, {id, email}) =>
                data ?
                    <a className={"cv-email-link"} href={`mailto: ${email}`}><TableCellInnerContainer content={email}/></a> :
                    <div className={"d-flex justify-content-center"}>-</div>
        },

        {
            ...createColumn(
                {
                    text: "Phone Number",
                    dataField: "phoneNumber",
                    width: "10%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (_, data) => (
                <Link to={`/employees-management/employee-info/${data?.userId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.phoneNumber}/>
                </Link> ||
                <div className={"d-flex justify-content-center"}>-</div>
            )
        },

        {
            ...createColumn(
                {
                    text: "Position/Title",
                    dataField: "position",
                    width: "10%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (_, data) => (
                <Link to={`/employees-management/employee-info/${data?.userId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.position}/>
                </Link> ||
                <div className={"d-flex justify-content-center"}>-</div>
            )
        },
        {
            ...createColumn(
                {
                    text: "City",
                    dataField: "city",
                    width: "7%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (_, data) => (
                <Link to={`/employees-management/employee-info/${data?.userId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.city}/></Link> ||
                <div className={"d-flex justify-content-center"}>-</div>
            )
        },

        {
            ...createColumn(
                {
                    text: "Date",
                    dataField: "createdAt",
                    width: "7%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (_, data) => (
                <Link to={`/employees-management/employee-info/${data?.userId}`} className="employee-field-item">
                    <TableCellInnerContainer content={moment(data?.createdAt).format(DATE_FORMAT)}/>
                </Link> ||
                <div className={"d-flex justify-content-center"}>-</div>
            )
        },
        {
            ...createColumn(
                {
                    text: "Application Status",
                    dataField: "status",
                    width: "9%",
                    isSortable: true
                },
                handleClick,
                sortingValue),
            formatter: (_, data) => (
                <Link to={`/employees-management/employee-info/${data?.userId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.status ? <CVStatus status={data?.employmentFormStatus} /> : <></>} />
                </Link>
            )
        },
        {
            ...createColumn(
                {
                    text: "Status",
                    dataField: "switchStatus",
                    width: "7%",
                },
                handleClick,
                sortingValue),
            formatter: (_, {id, status}) => <CVStatusSwitcher
                id={id}
                onHandleStatus={onHandleStatus}
                status={status}
            />
        },
        {
            ...createColumn(
                {
                    text: "Actions",
                    dataField: "action",
                    width: "5%",
                },
                handleClick,
                sortingValue),
            formatter: (_, {id}) => <>
                <DeclineButton id={id} onDeleteJobApplication={onDeleteJobApplication}/>
            </>
        },


    ];
}