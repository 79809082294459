export const MAX_LENGTH = 50;
export const ZIP_MAX_LENGTH = 5;
export const MIN_POSITION_LENGTH = 3;
export const MIN_POSITION_SEARCH_CHAR = 3;
export const MIN_CERTIFICATES_LENGTH = 3;
export const MIN_CITY_LENGTH = 3;
export const MIN_DESCRIPTION_LENGTH = 3;
export const MIN_MESSAGE_FIELD = 3;
export const MAX_MESSAGE_FIELD = 100;
export const MAX_DESCRIPTION_FIELD = 1000;
export const ZIP_CODE = /^(\d){5}$/g;
export const DELETING_TAGS_PATTERN = '<[^>]*>'
export const IS_LIST_PATTERN = '<li>'
export const EMPTY_DESCRIPTION_CONTAINER = "<p></p>"




export const POSITION_VALID_ERROR = 'Position/Title must contain at least 3 characters!';
export const CITY_VALID_ERROR = 'City must contain at least 3 characters!';
export const DESCRIPTION_VALID_ERROR = 'Must be from 3 to 1000 symbols.';
export const CERTIFICATES_VALID_ERROR = 'Certificates must contain at least 3 characters!';

export const POSITION_REQUIRED_ERROR = 'Position/Title is required!';
export const UNION_REQUIRED_ERROR = 'Union is required!';
export const DESCRIPTION_REQUIRED_ERROR = 'Description is required!';

export const ZIP_CODE_ERROR = 'Zip code is not valid format!';


//SEND EMAIL TO USER
export const SUBJECT_REQUIRED_ERROR = 'Subject must contain at least 1 character';
export const MESSAGE_REQUIRED_ERROR = 'Free text is required.';
export const MIN_MESSAGE_FIELD_ERROR = 'Must be from 3 to 100 symbols.'

// RADIO STATUSES
export const TRUE_RADIO_STATUS = 'true'
export const FALSE_RADIO_STATUS = 'false'


export const EDIT_JOB_MODAL_TITLE = "Close Edit Job?";
export const EDIT_JOB_MODAL_TEXT = "Are you sure you want to close Edit Job? Changes you made may not be saved.";



