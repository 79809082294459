import * as yup from "yup";
import {
    CERTIFICATE_TYPE,
    CERTIFICATE_NAME,
} from "./certificate";
import {
    CERTIFICATES_NAME_REQUIRED_ERROR,
    CERTIFICATES_TYPE_REQUIRED_ERROR
} from "../../constants/certificates-constants"

export const validationSchema = yup.object().shape({
    type: CERTIFICATE_TYPE.required(CERTIFICATES_TYPE_REQUIRED_ERROR),
    name: CERTIFICATE_NAME.required(CERTIFICATES_NAME_REQUIRED_ERROR),
});

export const initialValues = {
    type: "",
    name: "",
};