import React from 'react';
import className from "classnames"
import { useField } from 'formik';
import CustomInput from './Input';

const FormikInput = ({
                         placeholder,
                         name,
                         type,
                         readOnly,
                         label,
                         containerClassName,
                         classNameInput,
                         maxLength,
                     }) => {
    const [{onBlur,onChange, value}, meta] = useField({ name, type, placeholder });
    const isError = meta.error && meta.touched;
    const inputClasses = className({"is-invalid ":isError}, {"certificate-field-input": classNameInput} )
    const labelClasses = className({"text-danger " :isError},  "auth-form-label" )
    return (
        <section className={containerClassName}>
            <label
                className={labelClasses}
            >
                {label}
            </label>
            <div>
            <CustomInput
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                type={type}
                className={inputClasses}
                readOnly={readOnly}
                placeholder={placeholder}
                maxLength={maxLength}
            />

            <span className="invalid-feedback">{meta.error}</span>
            </div>
        </section>
    )
};

export default FormikInput;