import * as yup from 'yup';

import {
    MIN_FIRST_NAME_LENGTH,
    MIN_LAST_NAME_LENGTH,
    MIN_COMPANY_NAME_LENGTH,
    PHONE_NUMBER_PATTERN,
    EMAIL_PATTERN,
    MIN_PHONE_NUMBER_LENGTH,
    FIRST_NAME_VALID_ERROR,
    LAST_NAME_VALID_ERROR,
    COMPANY_NAME_VALID_ERROR,
    EMAIL_VALID_ERROR,
    PHONE_NUMBER_VALID_ERROR,
    } from '../../constants/employer-creation-constants';



export const FIRST_NAME = yup
    .string()
    .min(MIN_FIRST_NAME_LENGTH, FIRST_NAME_VALID_ERROR)

export const LAST_NAME = yup
    .string()
    .min(MIN_LAST_NAME_LENGTH, LAST_NAME_VALID_ERROR)

export const COMPANY_NAME = yup
    .string()
    .min(MIN_COMPANY_NAME_LENGTH, COMPANY_NAME_VALID_ERROR)

export const EMAIL = yup
    .string()
    .matches(EMAIL_PATTERN, EMAIL_VALID_ERROR)

export const PHONE_NUMBER = yup
    .string()
    .min(MIN_PHONE_NUMBER_LENGTH, )
    .matches(PHONE_NUMBER_PATTERN, PHONE_NUMBER_VALID_ERROR)
