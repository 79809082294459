import React, {useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

// formik-validation
import {Formik} from "formik";
import { validationSchema} from "../../validators/creationPositionFormValidation/form";
import { MAX_LENGTH} from "../../constants/job-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import ButtonsContainer from "../../components/ButtonsContainer/ButtonsContainer";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import ModalExample from "../../components/Modal/Modal";

import { EDIT_POSITION_MODAL_TEXT,
    EDIT_POSITION_MODAL_TITLE
} from "../../constants/positions-constants";
import "../JobCreationForm/JobCreationForm.scss";
import positionsService from "../../services/PositionsService";

const PositionEditForm = () => {
    const [item, setItem] = useState({})
    const [isDirty, setIsDirty] = useState(false)
    const [isDisabledButton, setIsDisabledButton] = useState(false)
    const [isModal, setIsModal] = useState(false);

    const history = useHistory()
    const params = useParams()

    const breadcrumbs = useMemo(() => ([
        {
            linkTo: "/job-listing",
            title: "Job Listing",
        },
        {
            linkTo: "/job-listing/positions-list",
            title: "Positions/Titles",
        },
        {
            linkTo: `/job-listing/position-edit/${params.id}`,
            title: "Edit Position/Title",
        },
    ]), [params.id])

    useEffect(() => {
        positionsService.getPositionById(params.id)
            .then((response) => {
                setItem(response?.data)
            })

    }, [])

    const initialValues = {
        name: item?.name,
    };

    const handleEditPosition = ({
                                    name,
                                }) => {
        const data = {
            name,
        };
        setIsDisabledButton(true)
        positionsService.editPosition(item.id, data)
            .then(() => {
                history.push("/job-listing/positions-list")
            })
            .finally(() => setIsDisabledButton(false))
    }

    const toggle = () => setIsModal(!isModal);

    const handleCancelButtonClick = (prevValue, currentValue) => {
        if (prevValue !== currentValue) {
            toggle()
        } else {
            history.push("/job-listing/positions-list")
        }
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle}/>
            {item.hasOwnProperty("name") &&
            <Card className="creation-job-card">
                <CardBody className="creation-job-card">
                    <div className="creation-job-title">Edit Position/Title</div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleEditPosition}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, dirty, setFieldValue, values, isValid, ...p}) => {
                            setIsDirty(dirty)
                            return (

                                <form className="form-horizontal  creation-job-form" onSubmit={handleSubmit}>
                                    <div className="creation-job-form-group">

                                        <FormikInput
                                            id="name"
                                            label="Name"
                                            name="name"
                                            containerClassName="creation-job-card-group"
                                            type="text"
                                            placeholder="Input Name"
                                            maxLength={MAX_LENGTH}
                                        />

                                    </div>
                                    <ButtonsContainer
                                        isDisabled={!(isValid) || isDisabledButton}
                                        onCancelButtonClick={() => handleCancelButtonClick(initialValues.name, values.name)}
                                        submitButtonContent={"Save"}
                                    />

                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            onToggle={toggle}
                                            path={"/job-listing/positions-list"}
                                        />}
                                        isModal={isModal}
                                        title={EDIT_POSITION_MODAL_TITLE}
                                        text={EDIT_POSITION_MODAL_TEXT}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
            }
        </>
    );
};


export default PositionEditForm;

