import * as yup from 'yup';

import {
    MIN_POSITION_LENGTH,
    POSITION_VALID_ERROR,} from '../../constants/job-creation-constants';



export const JOB_POSITION = yup
    .string()
    .min(MIN_POSITION_LENGTH, POSITION_VALID_ERROR)

