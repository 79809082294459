import React from "react";
import moment from "moment";
import {Link} from "react-router-dom";

import {createColumn} from "../../helpers/createColumn";

import {ASC_LABEL, DESC_LABEL} from "../../constants/table-constant";
import EmploymentFormsDownload from "../../pages/EmploymentForms/EmploymentFormsDownload";
import TableCellInnerContainer from "../TableCellInnerContainer";


export const TableStructure = (
    sortingValue,
    onSetSortingValue,
    getEmploymentFromPdF
) => {
    const handleClick = (dataField) => {
        if (sortingValue?.column !== dataField) {
            onSetSortingValue({column: dataField, direction: ASC_LABEL})
        } else {
            onSetSortingValue({
                column: dataField,
                direction: sortingValue?.direction === ASC_LABEL ? DESC_LABEL : ASC_LABEL
            })
        }

    }

    return [
        {
            ...createColumn(
            {
                text: "Full Name",
                dataField: "fullName",
                width: "27%",
                isSortable: true
            },
            handleClick,
            sortingValue),
            formatter: (_, data) =>
                <Link to={`employees-management/employee-info/${data?.employmentsId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.fullName}/>
                </Link>
        },
        {
            ...createColumn(
            {
                text: "Phone",
                dataField: "phoneNumber",
                width: "10%",
                isSortable: true
            },
            handleClick,
            sortingValue),
            formatter: (_, data) =>
                <Link to={`employees-management/employee-info/${data?.employmentsId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.phoneNumber}/>
                </Link>
        },
        {
            ...createColumn(
            {
                text: "Email",
                dataField: "email",
                width: "22%",
                isSortable: true
            },
            handleClick,
            sortingValue),
            formatter: (_, data) =>
                <Link to={`employees-management/employee-info/${data?.employmentsId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.email}/>
                </Link>
        },

        {
            ...createColumn(
            {
                text: "Date",
                dataField: "date",
                width: "10%",
                isSortable: true
            },
            handleClick,
            sortingValue),
            formatter: (_, data) =>
                <Link to={`employees-management/employee-info/${data?.employmentsId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.date}/>
                </Link>
        },

        {
            ...createColumn(
            {
                text: "Time",
                dataField: "time",
                width: "10%",
            },
            handleClick,
            sortingValue),
            formatter: (_, data) =>
                <Link to={`employees-management/employee-info/${data?.employmentsId}`} className="employee-field-item">
                    <TableCellInnerContainer content={data?.time}/>
                </Link>
        },

        {
            ...createColumn(
                {
                    text: "Actions",
                    dataField: "download",
                    width: "19%",
                },
                handleClick,
                sortingValue),
            formatter: (_, data) =>
                <>
                <EmploymentFormsDownload
                    downloadFunction={getEmploymentFromPdF(data, true)}
                    viewFunction={getEmploymentFromPdF(data)}
                />
            </>

        },


    ];
}