export default {
    // Container styles
    DEFAULT_COLOR: "#000000",
    SELECTED_COLOR: "#B2D4FF",
    FOCUSED_COLOR: "#eee",

    // Options style
    OPTION_BORDER_COLOR: "#eee",
    OPTION_BORDER_HOVER_COLOR: "#eee",
    OPTION_BORDER_FOCUS_COLOR: "#faa31b",
    OPTION_BORDER_DISABLED_COLOR: "#eee",
    OPTION_BACKGROUND_DEFAULT_COLOR: "transparent",
    OPTION_DEFAULT_COLOR: "#333333",
    OPTION_DISABLED_COLOR: "#495057",
    PLACEHOLDER_DEFAULT_COLOR: "#999999",
    PLACEHOLDER_DISABLED_COLOR: "#999999",
};