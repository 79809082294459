import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import './EmployeeInfo.scss';
import employeesManagementService from "../../services/EmployeesManagementService";
import Breadcrumb from "../../components/Common/breadcrumb";
import {Card, CardBody} from "reactstrap";
import userPlaceholder from "../../assets/images/user-placeholder2.png"
import EmployeeInfoField from "./EmployeeInfoField";
import FileInteraction from "./FileInteraction";
import {useDispatch} from "react-redux";
import {getEmploymentFormPdf} from "../../store/employmentForm/actions";
import {CERTIFICATES_ERROR_LABEL, EMPLOYMENT_ERROR_LABEL, RESUME_ERROR_LABEL} from "../../constants/cv-list-constatns";
import {getCvPdf} from "../../store/cv/actions";

const EmployeeInfo = () => {
    const [user, setUser] = useState({})
    const {personalInfo} = user;
    const dispatch = useDispatch();
    const {id} = useParams()
    const breadcrumbs = [
        {
            linkTo: "/employees-management/list-of-employees",
            title: "Employees Management",
        },
        {
            linkTo: `/employees-management/employee-info/${user.id}`,
            title: "Employee’s Info",
        },
    ];

    const getEmploymentFromPdF = (id, email, needDownload = false) => {
        const params = {id, email};
        if (needDownload) Object.assign(params, {needDownload});
        dispatch(getEmploymentFormPdf(params));
    };

    const getCvPdF = (id, email, needDownload = false) => {
        const params = {id, email};
        if (needDownload) Object.assign(params, {needDownload});
        dispatch(getCvPdf(params));
    };

    useEffect(() => {
        employeesManagementService.getEmployeeById(id)
            .then((response) => {
                setUser(response.data)
            })
    }, [])

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            <Card className="employee-info-card">
                <CardBody className="employee-info-card-body">
                    <div className="employee-info-card-title">Employee’s Info</div>
                    <div className="d-flex">
                        <img alt="avatar" src={personalInfo?.avatar?.path || userPlaceholder}
                             className="employee-info-card-avatar"/>
                        <div className="employee-info-card-content">
                            <div>
                                <EmployeeInfoField description="First name" value={personalInfo?.firstName}/>
                                <EmployeeInfoField description="Last name" value={personalInfo?.lastName}/>
                                <EmployeeInfoField description="Phone number" value={user?.phoneNumber}/>
                                <EmployeeInfoField description="Email" value={user?.email}/>
                                <EmployeeInfoField description="Zip code" value={user?.zipCode}/>
                                <EmployeeInfoField description="City" value={user?.city}/>
                            </div>
                            {(user?.employmentFormId || user?.resumeFileId || user?.CertificatesFileId) &&
                                <div className="employee-info-card-documents-title">Documents</div>}
                            <div className="d-flex">
                                {user?.employmentFormId &&
                                    <FileInteraction title="Employment Application"
                                                     id={user?.employmentFormId}
                                                     name={user?.email || EMPLOYMENT_ERROR_LABEL}
                                                     onGetPdf={getEmploymentFromPdF}
                                    />}
                                {user?.resumeFileId &&
                                    <FileInteraction title="Resume"
                                                     id={user?.resumeFileId}
                                                     name={user?.email || RESUME_ERROR_LABEL}
                                                     onGetPdf={getCvPdF}
                                    />}
                                {user?.CertificatesFileId &&
                                    <FileInteraction title="Certificates"
                                                     id={user?.CertificatesFileId}
                                                     name={user?.email || CERTIFICATES_ERROR_LABEL}
                                                     onGetPdf={getCvPdF}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default EmployeeInfo;