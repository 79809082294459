import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { META_TITLE } from "../../constants/layout-constantns";
import Header from "./header/header";
import Sidebar from "./sidebar/sidebar";
import {adminRoutes} from "../../routes/allRoutes"

const AdminLayout = ({ children, role }) => {
  useEffect(() => {
    document.title = META_TITLE;
  }, []);
  return (
    <>

        <Sidebar routes={adminRoutes}/>
      <div className="main-content">
        <Header />
        {children}
      </div>

    </>
  );
};

export default withRouter(AdminLayout);
