import Axios from 'axios';

import Http from './HttpService';
import StorageService from "./StorageService";
import waitPromiseResolve from "../helpers/waitPromiseResolved";

const USER_PLACEHOLDER = {
    firstName: "FirstName",
    lastName: "LastName"
};

class AuthService extends Http {

    getUser() {
        const user = StorageService.user.value;
        if (user) {
            return user;
        } else  {
            return USER_PLACEHOLDER
        }
    }

    login(model, remember) {
        this.clearUser();
        return this
            .post('admin-sessions', model)
            .then((authUser) => {
                this.storeUser(authUser, remember);
                return authUser
            });
    }

    signOut() {
        return this.delete('admin-sessions')
            .then(() => {
                this.clearUser();
            });
    }

    restorePassword(model) {
        return this.put('admin-verifications/password', model);
    }

    forgotPassword(email) {
        return this.post('admin-verifications/password', { email })
    }

    confirmEmail(model) {
        return this.put('admin-verifications/confirm-email', model)
    }

    storeUser(userData, remember = false) {
        const { user, token } = userData.data;
        const storage = remember ? localStorage : sessionStorage;
        StorageService.user.storage = storage;
        StorageService.session.storage = storage;
        StorageService.user.value = user;
        StorageService.session.value = token;
    }

    clearUser() {
        StorageService.user.clear();
        StorageService.session.clear();
    }
}

export default new AuthService();

