import Http from "./HttpService";


class HandbooksService extends Http {

    uploadHandbook (language, file) {
        return this.post(`admin-handbook?handbookLanguage=${language}`, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        } )
    }

    getHandbook() {
        return this.get(`admin-handbook`)
    }
    deleteHandbook(id) {
        return this.delete(`admin-handbook/${id}`)
    }
}


export default new HandbooksService()