import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { UPDATE_PASSWORD } from "./actionTypes";
import ResetPasswordService from "../../services/ResetPasswordService";
import { userUpdatePasswordSuccess } from "./actions";

const userUpdatePasswordAsync = async (data) => {
  const response = await ResetPasswordService.updatePassword(data);
  return response.data;
};

function* userUpdatePassword({
  payload: {
    data: { oldPassword, password, confirmPassword },
  },
}) {
  try {
    const response = yield call(userUpdatePasswordAsync, {
      oldPassword,
      password,
      confirmPassword,
    });
    if (response) {
      yield put(userUpdatePasswordSuccess(true));
    }
  } catch (error) {
    toast.error(error.message)
    toast.clearWaitingQueue()
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, userUpdatePassword);
}

function* updatePasswordSaga() {
  yield all([fork(watchUpdatePassword)]);
}

export default updatePasswordSaga;
