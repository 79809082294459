import React, {useState} from "react";
import {ReactComponent as Upload} from "../../assets/images/ic-cloud-upload-black.svg";
import {ReactComponent as Delete} from "../../assets/images/delete-icon.svg";
import {useDropzone} from "react-dropzone";
import {ENGLISH_HANDBOOK_LABEL} from "../../constants/upload-handbook-constants";
import DeleteModalButtons from "../Modal/ModalButtonContainers/DeleteModalButtons";
import ModalExample from "../Modal/Modal";
import {DELETE_HANDBOOK_MODAL_TEXT, DELETE_HANDBOOK_MODAL_TITLE} from "../../constants/handbook-constants";

export  const HandbookDropzoneContainer = ({ handbook, isDisabled, onDrop, label, onDeleteHandbook }) => {
    const [isModal, setIsModal] = useState(false);

    const toggle = () => setIsModal(!isModal);

    const firstLetterOfName = handbook?.name[0]
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, disabled: isDisabled });


    return <div {...getRootProps()}  className="handbooks-upload" >
        <input {...getInputProps()} disabled={isDisabled}/>
        <div className={isDisabled ? "handbooks-upload-container handbooks-upload-container-disabled" : "handbooks-upload-container"}>
            <Upload className="handbooks-upload-icon"/>
            {label === ENGLISH_HANDBOOK_LABEL ?
                <div className="handbooks-upload-label-major">Drop file here or click to upload for English Handbook</div> :
                <div className="handbooks-upload-label-major">Drop file here or click to upload for Spanish Handbook</div>
            }
            <div className="handbooks-upload-label-minor">Maximum 1 file</div>
        </div>
        {handbook &&
        <div className="handbooks-item-container">
            <a href={handbook?.path} target="_blank">
                <div className="handbooks-item">
                    <div className="handbooks-item-icon">{firstLetterOfName}</div>
                    <div className="handbooks-item-info">
                        <div className="handbooks-item-info-name">{handbook.name}</div>
                        <div className="handbooks-item-info-size">{`Size : ${handbook.fileSize} KB`}</div>
                    </div>
                </div>
            </a>
            <Delete className={"handbooks-item-delete"} onClick={toggle}/>

        </div>
        }
        <ModalExample
            onToggle={toggle}
            isModal={isModal}
            title={DELETE_HANDBOOK_MODAL_TITLE}
            text={DELETE_HANDBOOK_MODAL_TEXT}
            container={
                <DeleteModalButtons
                    onToggle={toggle}
                    onDelete={()=>{
                        onDeleteHandbook(handbook.id)
                        toggle()
                    }}
                />
            }
        />
    </div>
}