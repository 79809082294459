import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import ChangePassword from "./auth/change-password/reducer";
import GetEmployment from "./employmentForm/reducer";
import UpdatePassword from "./update-password/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Profile,
  ChangePassword,
  GetEmployment,
  UpdatePassword,
});

export default rootReducer;
