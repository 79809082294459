import { takeEvery, fork, all, call } from "redux-saga/effects";
import {toast} from "react-toastify";
import {PDF_DOWNLOAD} from "../../constants/message-constans";
import fileSaver from "../../helpers/fileSaver";
import SearchCVServices from "../../services/SearchCVServices";
import {GET_CV_PDF} from "./actionTypes";

const getPdfAsync = async (id) => {
    return await SearchCVServices.getPDF(id);
};

function* getCv({ payload: { id, email, needDownload = false } }) {
    try {
        toast.success(PDF_DOWNLOAD);
        toast.clearWaitingQueue()
        const response = yield call(getPdfAsync, id);
        if (needDownload) {
            fileSaver(response, `${email}.pdf`)
        } else {
            if (typeof window === "undefined") return;
            const url = window.URL.createObjectURL(response);
            window.open(url);
        }
    } catch (error) {
        toast.clearWaitingQueue()
        toast.error("Resume not found");
    }
}

export function* watchCvPdf() {
    yield takeEvery(GET_CV_PDF, getCv);
}

function* cvSaga() {
    yield all([fork(watchCvPdf)]);
}

export default cvSaga;