
export const POSITION_LIST_COLUMNS = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
    },
    {
        label: "Date of creating",
        field: "createdAt",
        sort: 'disabled',
    },
    {
        label: "Action",
        field: "action",
        sort: 'disabled',
    },

];

export const DATE_FORMAT = 'MM.DD.YY';


export const DELETE_POSITION_MODAL_TITLE = "Delete Position/Title?";
export const DELETE_POSITION_MODAL_TEXT = "Are you sure you want to delete the chosen Position/Title?";

export const CREATE_POSITION_MODAL_TITLE = "Close Create Position/Title?";
export const CREATE_POSITION_MODAL_TEXT = "Are you sure you want to close Create Position/Title screen?";

export const EDIT_POSITION_MODAL_TITLE = "Close Edit Position/Title?";
export const EDIT_POSITION_MODAL_TEXT = "Are you sure you want to close Edit Position/Title? Changes you made may not be saved.";

