import React from "react";
import {Redirect} from "react-router-dom"
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePwd from "../pages/Authentication/ChangePassword";
import EmploymentForms from "../pages/EmploymentForms/EmploymentForms";
import JobCreationForm from "../pages/JobCreationForm/JobCreationForm";
import JobList from "../pages/JobList/JobList";
import Page404 from "../pages/404/404";
import Settings from "../pages/Settings/Settings";
import EmployersManagement from "../pages/EmployersManagement/EmployersManagement";
import EmployeesManagement from "../pages/EmployeesManagement/EmpoymeesManagment";
import CertificatesList from "../pages/CertificatesList/CertificatesList";
import EmployeeHandbooks from "../pages/EmployeeHandbooks/EmployeeHandbooks";
import JobEditForm from "../pages/JobEditForm/JobEditForm";
import EmployerCreationForm from "../pages/EmpoyerCreationForm/EmployerCreationForm";
import CVList from "../pages/CVList/CVList";
import ConfirmEmailPage from "../pages/Authentication/ConfirmEmail";
import CertificateDetails from "../pages/CertificateDetails/CertificateDetails";
import SendEmail from "../pages/SendEmail/SendEmail";
import EmployerDetails from "../pages/EmployerDetails/EmployerDetails";
import EmployeeInfo from "../pages/EmployeeInfo/EmployeeInfo";
import EmployerEdit from "../pages/EmployerEdit/EmployerEdit";
import EmployeeCreationForm from "../pages/EmployeeCreationForm/EmployeeCreationForm";
import CertificateCreation from "../pages/CertificateCreation/CertificateCreation";
import EmployeeEdit from "../pages/EmployeeEdit/EmployeeEdit";
import CertificateEdit from "../pages/CertificateEdit/CertificateEdit";
import PositionsList from "../pages/PositionsList/PositionsList";
import PositionCreationForm from "../pages/PositionCreationForm/PositionCreationForm";
import PositionEditForm from "../pages/PositionEditForm/PositionEditForm";



const adminRoutes = [
  { path: "/employment-forms", label: "Employment Applications", component: EmploymentForms },
  { path: "/employees-management/employee-info/:id",  component: EmployeeInfo},
  { path: "/employees-management/list-of-employees",  component: EmployeesManagement },
  { path: "/employees-management/employee-creation",  component: EmployeeCreationForm },
  { path: "/employees-management/edit-employee/:id",  component: EmployeeEdit},
  { path: "/employees-management", label: "Employees Management", component: () => (<Redirect to={"/employees-management/list-of-employees"} />) },
  { path: "/job-listing/list-of-jobs", component: JobList },
  { path: "/job-listing/job-creation",  component: JobCreationForm },
  { path: "/job-listing/job-edit/:id",  component: JobEditForm },
  { path: "/job-listing/cv-searching",  component: CVList },
  { path: "/job-listing/positions-list",  component: PositionsList },
  { path: "/job-listing/position-creation",  component: PositionCreationForm },
  { path: "/job-listing/position-edit/:id",  component: PositionEditForm},
  { path: "/job-listing/send-email",  component: SendEmail },
  { path: "/job-listing", label: "Job Listing",  component: () => (<Redirect to={"/job-listing/list-of-jobs"} />) },
  { path: "/employee-handbooks", label: "Employee Handbooks", component: EmployeeHandbooks },
  { path: "/settings", label: "Settings", component: Settings },
];

const superAdminRoutes = [
  { path: "/employers-management/list-of-employers", component: EmployersManagement },
  { path: "/employers-management/employer-creation", component: EmployerCreationForm },
  { path: "/employers-management/employer-details/:id", component: EmployerDetails },
  { path: "/employers-management/employer-edit/:id", component: EmployerEdit },
  { path: "/employers-management", label: "Customers Management", component: () => (<Redirect to={"/employers-management/list-of-employers"} />) },
  { path: "/certification-library/certificate-creation",  component: CertificateCreation },
  { path: "/certification-library/list-of-certificates",  component: CertificatesList },
  { path: "/certification-library/detailed-certificate/:id",  component: CertificateDetails },
  { path: "/certification-library/certificate-edit/:id",  component: CertificateEdit },
  { path: "/certification-library", label: "Certification Library", component: () => (<Redirect to={"/certification-library/list-of-certificates"} />) },
  { path: "/settings-super", label: "Settings", component: Settings },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ChangePwd },
  { path: "/confirm-email", component: ConfirmEmailPage },
];

const notFoundRoutes = [
  {component: Page404}
]

export { adminRoutes, authRoutes, superAdminRoutes, notFoundRoutes };


