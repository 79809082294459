import React, {useState} from "react";
import {ReactComponent as Delete} from '../../assets/images/delete-icon.svg'
import {ReactComponent as Edit} from '../../assets/images/edit-icon.svg'
import './JobListActions.scss';
import { Link} from "react-router-dom";

const JobListActions = ({id, onClick, path}) => {
    return (<div className="actions-container">
            <Link  to={path} id={id} className="actions-control actions-edit" ><Edit/></Link>
            <button  id={id} onClick={()=> onClick(id)} className="actions-control actions-delete" ><Delete/></button>
    </div>)
};

export default JobListActions;
