import React from "react";
import { Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

import authValidator from "../../validators/auth.validators";

import "./login.scss";

const ForgetPasswordPage = ({
  userForgetPassword,
  history,
}) => {
  function handleValidSubmit(event, values) {
    userForgetPassword(values, history);
  }

  return (
    <Container className="login-container">
      <div className="login-wr">
        <div className="login-head">
          <Link to="/" className="title">
            CertiHire
          </Link>
          <div className="subtitle">Welcome to CertiHire</div>
          <div className="text">Enter valid email to get restore password</div>
        </div>
        <div>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
          >
            <div className="form-group">
              <AvField
                name="email"
                label="Email"
                className="form-control"
                placeholder="Enter email"
                type="email"
                validate={authValidator.email}
              />
            </div>

            <button className="login-bth" type="submit">
              Continue
            </button>
          </AvForm>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(
  connect(null, { userForgetPassword })(ForgetPasswordPage),
);
