import React, {useCallback, useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import Select from "react-select";

import { useField } from "formik";
import { FormGroup } from "reactstrap";
import {customStyles} from "../SelectComponent/defaultSelectStyle";
import {MIN_POSITION_SEARCH_CHAR} from "../../constants/job-creation-constants";



const FormikMultiSelect = ({ name, options, defaultValue, placeholder, initialOption, label, onInputChange, description, search, onRequestAPI = () => {}, ...props }) => {
    const [field, { error, touched }, helpers] = useField({ name });

    const handleChange = useCallback((options) => {
        const getValueOptions = options?.map(({ id }) => id);
        helpers.setValue(getValueOptions);
        helpers.setTouched(true);
    }, [field.value]);

    const Component = Select;

    return (
        <FormGroup className="mb-3">
            {label &&  (
                <label
                    htmlFor={props.name}
                    className={classNames({ "text-danger": error && touched })}
                >
                    {label}
                </label>
            )}
            <Component
                menuIsOpen={search?.length >= MIN_POSITION_SEARCH_CHAR}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                defaultValue={defaultValue}
                options={options}
                onChange={handleChange}
                onBlur={()=> helpers.setTouched(true)}
                placeholder={placeholder}
                getOptionValue={({id}) => id}
                styles={customStyles}
                onInputChange={onInputChange}
                components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: null,
                }}
                noOptionsMessage={() => "There are no such Certificates created yet."}
                isValidNewOption={value => !!value}
            />


        </FormGroup>
    );
};


export default FormikMultiSelect;