import * as yup from "yup";
import {FIRST_NAME, LAST_NAME, EMAIL, PHONE_NUMBER} from "./employee";
import {
    FIRST_NAME_REQUIRED_ERROR,
    LAST_NAME_REQUIRED_ERROR,
    EMAIL_REQUIRED_ERROR,
    PHONE_NUMBER_REQUIRED_ERROR,
} from '../../constants/employer-creation-constants';

export const validationSchema = yup.object().shape({
    firstName: FIRST_NAME.required(FIRST_NAME_REQUIRED_ERROR),
    lastName: LAST_NAME.required(LAST_NAME_REQUIRED_ERROR),
    email: EMAIL.required(EMAIL_REQUIRED_ERROR),
    phoneNumber: PHONE_NUMBER.required(PHONE_NUMBER_REQUIRED_ERROR),
});

export const initialValues = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
};