import * as yup from 'yup';

import {
    MIN_CERTIFICATES_LENGTH,
    CERTIFICATES_VALID_ERROR} from '../../constants/certificates-constants';



export const CERTIFICATE_TYPE = yup
    .string()
    .min(MIN_CERTIFICATES_LENGTH, CERTIFICATES_VALID_ERROR)

export const CERTIFICATE_NAME = yup
    .string()
    .min(MIN_CERTIFICATES_LENGTH, CERTIFICATES_VALID_ERROR)




