import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

// formik-validation
import {Formik, Field} from "formik";
import {initialValues, validationSchema} from "../../validators/creationJobFormValidation/form";
import {
    FALSE_RADIO_STATUS,
    MAX_LENGTH,
    MIN_POSITION_SEARCH_CHAR,
    TRUE_RADIO_STATUS,
    ZIP_MAX_LENGTH
} from "../../constants/job-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import GoogleAutocomplete from "../../components/JobCreationForm/GoogleAutocomplete"
import TextEditor from "../../components/TextEditor/TextEditor";
import SelectComponent from "../../components/SelectComponent/SelectComponent";

import jobsService from "../../services/JobsService"
import {removeEmptyFields} from "../../helpers/remove-epmty-object-fields"

import "./JobCreationForm.scss";
import MultiSelectComponent from "../../components/MultiSelectComponent/MultiSelectComponent";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import ModalExample from "../../components/Modal/Modal";
import {EDIT_EMPLOYEE_MODAL_TEXT, EDIT_EMPLOYEE_MODAL_TITLE} from "../../constants/employees-constants";


const breadcrumbs = [
    {
        linkTo: "/job-listing/list-of-jobs",
        title: "Job Listing",
    },
    {
        linkTo: "/job-listing/job-creation",
        title: "Job Creation",
    },
];


const JobCreationForm = () => {
    const [isDisabledButton, setIsDisabledButton] = useState(false)
    const [positionSearchValue, setPositionSearchValue] = useState('')
    const [certificatesSearchValue, setCertificatesSearchValue] = useState('')
    const [positionsList, setPositionsList] = useState([])
    const [certificatesList, setCertificatesList] = useState([])
    const [description, setDescription] = useState('')
    const [isModal, setIsModal] = useState(false);
    const [isDirty, setIsDirty] = useState(false)

    const history = useHistory()

    const handlePositionSearching = (value) => {
        setPositionSearchValue(value)
    }

    const handleCertificateSearching = (value) => {
        setCertificatesSearchValue(value)
    }

    const handleGetDescription = (value) => {
        setDescription(value)
    }

    const getPositionsList = () => {
        jobsService.getPositionsList(positionSearchValue)
            .then((response) => {
                const newData = response.data.map((item) => {
                    return {...item, label: item.name}
                })
                setPositionsList(newData)
            })
    }

    const getCertificatesList = () => {
        jobsService.getCertificatesList(certificatesSearchValue)
            .then((response) => {
                const newData = response.data.map((item) => {
                    return {...item, label: item.type}
                })
                setCertificatesList(newData)
            })
    }

    useEffect(() => {
        if (positionSearchValue.length >= MIN_POSITION_SEARCH_CHAR) {
            getPositionsList()
        }
    }, [positionSearchValue])

    useEffect(() => {
        if (certificatesSearchValue.length >= MIN_POSITION_SEARCH_CHAR) {
            getCertificatesList()
        }
    }, [certificatesSearchValue])

    const handleCreateJob = ({
                                 certificatesTypesIds,
                                 city,
                                 isUnion,
                                 location,
                                 positionId,
                                 zipCode
                             }) => {
        const data = {
            zipCode,
            positionId,
            description: description,
            isUnion: isUnion === TRUE_RADIO_STATUS,
            certificatesTypesIds,
            city,
            location: location ? location : {}
        };
        setIsDisabledButton(true)
        jobsService.createJob(removeEmptyFields(data))
            .then(() => {
                history.push("/job-listing/list-of-jobs")
            })
            .finally(() => setIsDisabledButton(false))
    }

    const toggle = () => setIsModal(!isModal);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs}  status={isDirty} toggle={toggle}/>
            <Card className="creation-job-card">
                <CardBody className="creation-job-card">
                    <div className="creation-job-title">Job Creation</div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleCreateJob}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, dirty, setFieldValue, values, isValid, ...p}) => {
                            setIsDirty(dirty)
                            return (
                                <form className="form-horizontal  creation-job-form" onSubmit={handleSubmit}>
                                    <div className="creation-job-form-group">
                                        <GoogleAutocomplete
                                            name="location"
                                            type="text"
                                            label="Address"
                                            error={errors}
                                            onSelect={(addressObject) => {
                                                setFieldValue("location", addressObject)
                                            }}
                                            placeholder="Input Address"
                                            placeId={values?.location?.placeId}
                                            allowClear
                                            maxLength={MAX_LENGTH}
                                        />

                                        <SelectComponent
                                            id="positionId"
                                            label="Position/Title*"
                                            name="positionId"
                                            search={positionSearchValue}
                                            options={positionsList}
                                            placeholder={"Input Position/Title"}
                                            onInputChange={handlePositionSearching}
                                        />
                                        <div className="creation-job-form-fieldset union-container">
                                            <div role="group" aria-labelledby="my-radio-group">
                                                <label className="custom-radio-label">
                                                    <Field className="custom-radio" type="radio" name="isUnion"
                                                           value={TRUE_RADIO_STATUS}/>
                                                    Union
                                                </label>
                                                <label className="custom-radio-label">
                                                    <Field className="custom-radio" type="radio" name="isUnion"
                                                           value={FALSE_RADIO_STATUS}/>
                                                    Non-union
                                                </label>
                                            </div>

                                            <em className={'required-notification'}>* - is required field;</em>
                                        </div>

                                        <MultiSelectComponent
                                            id="certificatesTypesIds"
                                            name="certificatesTypesIds"
                                            label="Certificates"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input Certificates"
                                            search={certificatesSearchValue}
                                            options={certificatesList}
                                            onInputChange={handleCertificateSearching}
                                        />

                                        <FormikInput
                                            name="zipCode"
                                            label="Zip Code"
                                            type="text"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input Zip Code"
                                            id="zipCode"
                                            containerClassName="creation-job-card-group"
                                            maxLength={ZIP_MAX_LENGTH}
                                        />
                                        <FormikInput
                                            name="city"
                                            label="City"
                                            type="text"
                                            className="creation-job-form-input form-control"
                                            placeholder="Input City"
                                            id="city"
                                            containerClassName="creation-job-card-group"
                                            maxLength={MAX_LENGTH}
                                        />

                                        <TextEditor
                                            name="description"
                                            label="Description*"
                                            placeholder="Input Description"
                                            id="description"
                                            onGetDescription={handleGetDescription}
                                        />

                                    </div>

                                    < button
                                        className="creation-job-form-button"
                                        type="submit"
                                        disabled={!(isValid) || isDisabledButton}
                                    >
                                        Create
                                    </button>
                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            onToggle={toggle}
                                            path={"/job-listing/list-of-jobs"}
                                        />}
                                        isModal={isModal}
                                        title={EDIT_EMPLOYEE_MODAL_TITLE}
                                        text={EDIT_EMPLOYEE_MODAL_TEXT}
                                    />

                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
        </>
    );
};


export default JobCreationForm;

