import React from 'react';
import Select from "react-select"
import {useField} from "formik";
import {customStyles} from "./defaultSelectStyle";
import {MIN_POSITION_SEARCH_CHAR} from "../../constants/job-creation-constants";
import "./SelectComponent.scss"

const SelectComponent = ({
                             label,
                             name,
                             search,
                             options,
                             onInputChange,
                             defaultValue,
                             placeholder,

                         }) => {
    const [, {error, touched},{setValue, setTouched}] = useField({name});

    const CustomSelect = Select;

    return (
        <>
            <section className="creation-job-card-group">
                <label className="auth-form-label">{label}</label>
                <CustomSelect
                    multi={true}
                    closeMenuOnSelect={false}
                    defaultValue={defaultValue}
                    menuIsOpen={search?.length >= MIN_POSITION_SEARCH_CHAR}
                    options={options}
                    onChange={(data) => {
                        setValue(data?.id?.toString())
                    }}
                    onInputChange={onInputChange}
                    onBlur={()=> setTouched(true)}
                    isClearable
                    components={{
                        // ClearIndicator: null,
                        IndicatorSeparator: null,
                        DropdownIndicator: null,
                    }}
                    noOptionsMessage={() => "There are no such Positions/Titles created yet. You can create it on the Positions/Titles creation screen."}
                    styles={customStyles}
                    placeholder={placeholder}
                />
                {touched && error && <span className="error-message">{error}</span>}
            </section>
        </>
    );
};

export default SelectComponent;