import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  IS_VALID_TOKEN
} from "./actionTypes";

export const userResetPassword = (data, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { data , history }
  };
};

export const userResetPasswordSuccess = message => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message
  };
};

export const userResetPasswordError = message => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: message
  };
};
  
export  const isValidToken = (data, history) => {
  return {
    type: IS_VALID_TOKEN,
    payload: { data, history }    
  }
}