import React from "react";
import { withRouter } from "react-router-dom";
import "./404.scss";

const Page404 = () => {

  return (
    <div className="not-found">
      <div className="not-found-title">404</div>
      <div className="not-found-subtitle">Page Not Found</div>
      <div className="not-found-text">
        Sorry, but the page you are looking for has not been found. Try checking
        the URL for error, then hit the refresh button on your browser.
      </div>
    </div>
  );
};

export default withRouter(Page404);
