import {ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const convertDataFromHtml = (data) => {
    const blocksFromHTML = htmlToDraft(data);
    return  ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );

}