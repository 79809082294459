import React from 'react';

const EmployeeInfoField = ({description, value}) => (
    <div className="employee-info-card-content-item">
        <div className="employee-info-card-content-description">{description}</div>
        <div>{value || <>-</>}</div>
    </div>
);


export default EmployeeInfoField;