import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import "./Table.scss"


const Table = ({columns, data, classes=""}) => {
    return (
        <div className={"table-container"}>
            <div className={"table-scroll-container"}>
            <BootstrapTable
                columns={ columns }
                keyField='id'
                data={ data }
                classes={"custom-table" + " " + classes}
            />
            </div>
        </div>
    );
};

export default Table;