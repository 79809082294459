import * as yup from "yup";
import {JOB_POSITION} from "./position";
import {POSITION_REQUIRED_ERROR} from "../../constants/job-creation-constants"

export const validationSchema = yup.object().shape({
    name: JOB_POSITION.required(POSITION_REQUIRED_ERROR),
});

export const initialValues = {
    name: "",
};
