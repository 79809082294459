import Http from "./HttpService";


class CertificatesService extends Http {

    getCertificates( Offset= 0, Limit=10) {
        return this.get(`admin-certificates`, {params: {Offset, Limit}})
    }
    createCertificate(model){
        return this.post('admin-certificates', model)
    }
    getCertificateById(id) {
        return this.get(`admin-certificates/${id}`)
    }
    editCertificate(id, model) {
        return this.put(`admin-certificates/${id}`, model)
    }
    deleteCertificate(id){
        return this.delete(`admin-certificates/${id}`)
    }
}


export default new CertificatesService()