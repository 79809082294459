import React, {useEffect, useState} from "react";
import { useHistory, useParams} from "react-router-dom";

// formik-validation
import {Formik} from "formik";

import {validationSchema} from "../../validators/creationEmployerFormValidation/form";
import {
    MAX_LENGTH,
    MAX_EMAIL_LENGTH,
    REPLACE_NUMBER_PATTERN,
} from "../../constants/employer-creation-constants"

// components

import FormikInput from "../../components/FormikComponents/FormikInput";
import FormikInputPhone from "../../components/FormikComponents/FormikInputPhone";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";

import employersService from "../../services/EmployersService";
import ModalExample from "../../components/Modal/Modal";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";
import {EDIT_EMPLOYER_MODAL_TEXT, EDIT_EMPLOYER_MODAL_TITLE} from "../../constants/employer-edit-constants";
import ButtonsContainer from "../../components/ButtonsContainer/ButtonsContainer";

const EmployerEdit = () => {
    const [item, setItem] = useState({})
    const [isModal, setIsModal] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    const toggle = () => setIsModal(!isModal);
    const history = useHistory()
    const params = useParams()

    const breadcrumbs = [
        {
            linkTo: "/employers-management/list-of-employers",
            title: "Customers Management",
        },
        {
            linkTo: `/employers-management/employer-edit/${item.id}`,
            title: "Customer Edit",
        },
    ];


    useEffect(()=>{
        employersService.getEmployerById(params.id)
            .then((response)  => {

                setItem(response.data)
            })
    }, [])

    const initialValue = {
        firstName: item?.personalInfo?.firstName,
        lastName: item?.personalInfo?.lastName,
        companyName: item?.personalInfo?.companyName,
        email: item?.email,
        phoneNumber: item?.phoneNumber?.slice(1),
    }

    const handleEditEmployer = ({
                                      firstName,
                                      lastName,
                                      companyName,
                                      email,
                                      phoneNumber,
                                  }) => {


        const data = {
            firstName,
            lastName,
            companyName,
            email,
            phoneNumber: "+"+phoneNumber.replace(REPLACE_NUMBER_PATTERN, ""),
        };
        employersService.editEmployer(item.id, data)
            .then(()=> history.push(`/employers-management/employer-details/${item.id}`))

    }

    const handleCancelButtonClick = (isTouched) => {
        if (isTouched) {
            toggle()
        } else {
            history.push(`/employers-management/employer-details/${item.id}`)
        }
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle}/>
            {item.hasOwnProperty("phoneNumber") &&
            <Card className="creation-employer-card">
                <CardBody className="creation-employer-card">
                    <div className="creation-employer-title">Customer Edit</div>
                    <Formik
                        initialValues={initialValue}
                        validationSchema={validationSchema}
                        onSubmit={handleEditEmployer}
                        validateOnMount
                                            >
                        {({errors, touched, handleSubmit, setFieldValue, dirty, values, isValid, handleChange,
                              handleBlur, ...p}) => {
                            setIsDirty(dirty)
                            return (

                                <form className="form-horizontal  creation-employer-form" onSubmit={handleSubmit}>
                                    <div className="creation-employer-form-group employer-form">

                                        <FormikInput
                                            id="firstName"
                                            label="First Name*"
                                            name="firstName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            placeholder="Input First Name"
                                            maxLength={MAX_LENGTH}
                                        />


                                        <FormikInput
                                            id="lastName"
                                            containerClassName="creation-employer-card-group"
                                            type="text"
                                            name="lastName"
                                            label="Last Name*"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Last Name"
                                            maxLength={MAX_LENGTH}
                                        />

                                        <FormikInput
                                            name="companyName"
                                            label="Company Name*"
                                            type="text"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Company Name"
                                            id="companyName"
                                            containerClassName="creation-employer-card-group"
                                            maxLength={MAX_LENGTH}
                                        />
                                        <FormikInput
                                            name="email"
                                            label="Email*"
                                            type="email"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Email"
                                            id="email"
                                            containerClassName="creation-employer-card-group"
                                            maxLength={MAX_EMAIL_LENGTH}
                                        />
                                        <FormikInputPhone
                                            name="phoneNumber"
                                            label="Phone Number*"
                                            type="tel"
                                            className="creation-employer-form-input form-control"
                                            placeholder="Input Phone Number"
                                            id="phoneNumber"
                                            containerClassName="creation-employer-card-group"
                                        />

                                        {errors.phone && touched.phone && (
                                            <div className="input-feedback">{errors.phone}</div>
                                        )}
                                    </div>
                                    <ButtonsContainer
                                        isDisabled={!(isValid)}
                                        onCancelButtonClick={() => handleCancelButtonClick(dirty)}
                                        submitButtonContent="Save"
                                    />
                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            id={item.id}
                                            onToggle={toggle}
                                            path={`/employers-management/employer-details/${item.id}`}
                                        />}

                                        isModal={isModal}
                                        title={EDIT_EMPLOYER_MODAL_TITLE}
                                        text={EDIT_EMPLOYER_MODAL_TEXT}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </CardBody>
            </Card>
            }
        </>
    );
};


export default EmployerEdit;
