import Http from "./HttpService";


class JobsService extends Http {

    createJob(model) {
        return this.post("admin-jobs", model)
    }

    getJobs(Offset = 0, Limit = 10) {
        return this.get(`admin-jobs`, {params: {Offset, Limit}})
    }

    getPositionsList(Search = '', Offset = 0, Limit = 100 ) {
        return this.get(`admin-jobs/positions`, {
            params: {
                Search,
                Limit,
                Offset,
            }
        })
    }

    getCertificatesList(Search = '', Offset = 0, Limit = 100 ) {
        return this.get(`/certificates/types`, {
            params: {
                Search,
                Limit,
                Offset,
            }
        })
    }

    getJobById(id) {
        return this.get(`admin-jobs/${id}`)
    }

    deleteJob(id) {
        return this.delete(`admin-jobs/${id}`)
    }

    editJob(id, model) {
        return this.put(`admin-jobs/${id}`, model)
    }

    setJobStatus(id, model) {
        return this.patch(`admin-jobs/${id}/status`, model)
    }

    sendEmail(model) {
        return this.post(`admin-users/email`, model)
    }
}


export default new JobsService()