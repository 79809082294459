import React, {useEffect, useState} from "react";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import JobListNavbar from "../../components/JobList/JobListNavbar";
import SearchControlsContainer from "../../components/SearchCV/SearchControlsContainer";
import cvServices from "../../services/SearchCVServices"
import Pagination from "../../components/Pagination/TablePagination";
import {CV_LIMIT} from "../../constants/pagination";
import {toast} from "react-toastify";
import {TableStructure} from "../../components/TableStructures/cvTableStructure";
import Table from "../../components/Tabel/Table";
import "./CVList.scss";
import Loader from "../../components/Loader";

const breadcrumbs = [
    {
        linkTo: "/job-listing",
        title: "",
        pageTitle: "Job Listing"
    },
];

const CVList = () => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showEmptyMessage, setShowEmptyMessage] = useState(false)
    const [totalCount, setTotalCount] = useState([])
    const [Offset, setOffset] = useState(0)
    const [sortingValue, setSortingValue] = useState(null)
    const [Search, setSearch] = useState('')

    const onSubmit = () => {
        getCVs({
            Offset: Search ? 0 : Offset,
            Search,
            OrderKey: sortingValue?.column,
            OrderDirection: sortingValue?.direction,
        })
    }

    const isNotEmpty = items.length

    const updateOffset = (newOffset) => {
        setOffset(newOffset)
    }

    const getCVs = (model) => {
        cvServices.getCVs(model)
            .then((response) => {
                setTotalCount(response.pagination.totalCount)

                setItems(response.data.map(item => {
                    return {
                        ...item,
                        position: item.position.name,
                        status: item.isAccepted
                    }
                }))
            })
            .finally(()=> {
                setShowEmptyMessage(true)
                setIsLoading(false)
            })
    }

    const handleDeleteJobApplication = (id) => {
        cvServices.deleteJobApplication(id)
            .then(() => getCVs({
                Offset,
                Search,
                OrderKey: sortingValue?.column,
                OrderDirection: sortingValue?.direction,
            }))
    }

    const handleToggleJobApplicationStatus = (id, status) => {
        if (status) {
            cvServices.acceptJobApplication(id).then(() => getCVs({
                Offset,
                Search,
                OrderKey: sortingValue?.column,
                OrderDirection: sortingValue?.direction,
            }))
        } else {
            cvServices.deleteAdminUser(id).then(() => getCVs({
                Offset,
                Search,
                OrderKey: sortingValue?.column,
                OrderDirection: sortingValue?.direction,
            }))
        }
    }

    const handleGetPdf = async (id, label) => {
        try {
            const response = await cvServices.getPDF(id)
            const fileURL = URL.createObjectURL(response);
            window.open(fileURL);
        } catch (error) {
            toast.error(`There are no ${label}`);
            toast.clearWaitingQueue()
        }
    }

    useEffect(() => {
        setIsLoading(true)
        getCVs({
            Offset,
            Search,
            OrderKey: sortingValue?.column,
            OrderDirection: sortingValue?.direction,
        })
    }, [Offset, sortingValue]);

    const columns = TableStructure(
        sortingValue,
        setSortingValue,
        handleToggleJobApplicationStatus,
        handleGetPdf,
        handleDeleteJobApplication
    )

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} field={"pageTitle"}/>
            <JobListNavbar/>
            <Card className="cv-forms-card">
                <CardBody>
                    <div className="cv-forms-title">
                        <div>Applied Resumes</div>

                    </div>
                    <SearchControlsContainer
                        Search={Search}
                        onSetSearch={data => {
                            setSearch(data)
                        }}
                        onSubmit={onSubmit}
                    />

                    {isNotEmpty ?
                        <>
                            <Table
                                columns={columns}
                                data={items}
                                classes={"cv-table"}
                            />
                            <Pagination
                                limit={CV_LIMIT}
                                offset={Offset || 0}
                                totalCount={totalCount}
                                onUpdateOffset={updateOffset}
                            />
                        </> :
                        <div className="cv-empty-container">
                            {isLoading && <Loader/> }
                            {showEmptyMessage && <div className="cv-empty-content">No Resumes applied yet</div>}
                        </div>
                    }
                </CardBody>
            </Card>
        </>
    );
};

export default CVList;
