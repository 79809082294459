import {getSortableClass} from "./getSortableClass";
import React from "react";

export  const createColumn = ({text, dataField, width, isSortable}, onClick, sortingValue) => {
    return {
        text: text,
        dataField: dataField,
        headerFormatter: ({dataField}) => {
            return isSortable ? <>
                <div className={"sortable" + " " + getSortableClass(dataField, sortingValue)}
                     onClick={() => onClick(dataField)}>{text}
                </div>
            </> : <span>{text}</span>
        },
        style: {
            width: width,
        }
    }
}