export const JOB_LIST_COLUMNS = [
    {
        label: "Position Title",
        field: "position",
        sort: "asc",
    },
    {
        label: "Status",
        field: "status",
        sort: "asc",
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
    },

];

export const navJobListingTab = [
    {
        label: "List of Jobs",
        path: "/job-listing/list-of-jobs"
    },
    {
        label: "Applied Resumes",
        path: "/job-listing/cv-searching"
    },
    {
        label: "Positions/Titles",
        path: "/job-listing/positions-list"
    },
]


export const DELETE_JOB_MODAL_TITLE = "Delete job";
export const DELETE_JOB_MODAL_TEXT = "Are You sure you want to delete the selected job?";