import React from 'react'

export default function CustomInput({
                                        value,
                                        onBlur,
                                        onChange,
                                        className,
                                        name,
                                        placeholder,
                                        readOnly,
                                        type,
                                        maxLength,
                                    }) {
    return (
        <input
            className={'form-control ' + className}
            type={type}
            value={(value || "").trimLeft()}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
            maxLength={maxLength}
            autoComplete={"off"}
        />
    );
}
