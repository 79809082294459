import React, { useState} from "react";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import Switcher from "../../components/Switcher/Switcher";
import {useHistory} from "react-router-dom";

import certificatesService from "../../services/CertificatesService"
import {MAX_LENGTH} from "../../constants/job-creation-constants"
import {
    createCertificateSwitchersData,
    REQUIRED_STATUS,
    NOT_REQUIRED_STATUS, EDIT_CERTIFICATE_MODAL_TITLE, EDIT_CERTIFICATE_MODAL_TEXT,
} from "../../constants/certificates-constants";
import {validationSchema, initialValues} from "../../validators/CertificateForm/form";
import FormikInput from "../../components/FormikComponents/FormikInput";
import {Formik} from "formik";
import "./CertificateCreation.scss"
import FormikCheckbox from "../../components/FormikComponents/FormikCheckbox";
import ModalExample from "../../components/Modal/Modal";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";


const breadcrumbs = [
    {
        linkTo: "/certification-library/list-of-certificates",
        title: "Certification Library",
    },
    {
        linkTo: `/certification-library/certificate-creation`,
        title: "Create Certificate",
    },
]


const CertificateCreation = () => {
    const [item, setItem] = useState({})
    const [isModal, setIsModal] = useState(false);
    const [isDirty, setIsDirty] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const history = useHistory()

    const newData = {...item}

    const handleAddCertificateField = (name, status) => {
        setIsChanged(true)
        if(status){
            newData[name] = NOT_REQUIRED_STATUS;
        } else {
          delete  newData[name]
        }
        setItem(newData)
    }

    const handleSetCertificateFieldStatus = (name, status) => {
        if(item.hasOwnProperty(name)){
            newData[name] = status ? REQUIRED_STATUS : NOT_REQUIRED_STATUS
            setItem(newData)
        }
    }

    const createCertificate = ({type, name}) => {
        const data = {
            ...item,
            type,
            name,
        }
        certificatesService.createCertificate(data)
            .then(()=> history.push("/certification-library/list-of-certificates"))
    }

    const toggle = () => setIsModal(!isModal);

    const handleCancelButtonClick = (status) => {
        if(isChanged || status){
            toggle()
        } else {
            history.push(`/certification-library/list-of-certificates`)
        }
    }


    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle} />
            <Card className="creation-job-card">
                <CardBody className="creation-certificate-body-card">
                    <div className="creation-job-title">Certificate Creation Form</div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={createCertificate}
                            validateOnMount
                        >
                            {({errors, touched, handleSubmit, dirty, setFieldValue, values, isValid, ...p}) => {
                                setIsDirty(dirty)
                                return (
                                    <form className="" onSubmit={handleSubmit}>
                                        <div className="certificate-fields-container">
                                            <div className="certificate-name-header ">Options</div>
                                            <div className="certificate-required-header">Required fields</div>
                                        </div>
                                        <div className="certificate-fields-container">
                                        <div className="certificate-name-field">
                                                <FormikInput
                                                    id="type"
                                                    label="Add Type*"
                                                    name="type"
                                                    containerClassName="certificate-field"
                                                    placeholder="Add Type"
                                                    classNameInput="certificate-field-input"
                                                    type="text"
                                                    maxLength={MAX_LENGTH}
                                                />
                                            </div>
                                            <div className="certificate-required-field"></div>
                                        </div>

                                        <div className="certificate-fields-container">
                                            <div className="certificate-name-field">
                                                <FormikInput
                                                    id="name"
                                                    containerClassName="certificate-field"
                                                    type="text"
                                                    name="name"
                                                    label="Add Name*"
                                                    placeholder="Add Name"
                                                    classNameInput="certificate-field-input"
                                                    maxLength={MAX_LENGTH}
                                                />
                                            </div>
                                            <div className="certificate-required-field"></div>
                                        </div>

                                            {createCertificateSwitchersData.map(({label, name}) => {
                                                return <div key={label}>
                                                <div className="certificate-fields-container" >
                                                    <div className="certificate-name-field">

                                                        <Switcher
                                                            name={name}
                                                            oneAddCertificateField={handleAddCertificateField}
                                                        />
                                                        <span className="certificate-field-label">{label}</span>
                                                    </div>
                                                    <div className="certificate-required-field">
                                                        <FormikCheckbox
                                                            name={name}
                                                            isDisabled={!item.hasOwnProperty(name)}
                                                            onChangeFieldStatus={handleSetCertificateFieldStatus}
                                                            isChecked={item[name] === REQUIRED_STATUS}
                                                        />
                                                    </div>
                                                </div>
                                                </div>
                                            })
                                            }

                                            <div className="button-container">
                                                <button
                                                    className="close-button"
                                                    type="button"
                                                    onClick={()=> handleCancelButtonClick(dirty)}
                                                >
                                                    Cancel
                                                </button>

                                                <button
                                                    className=" certificate-save-button"
                                                    type="submit"
                                                    disabled={!isValid}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        <ModalExample
                                            onToggle={toggle}
                                            container={<CloseModalButtons
                                                onToggle={toggle}
                                                path={"/certification-library/list-of-certificates"}
                                            />}
                                            id={item.id}
                                            isModal={isModal}
                                            title={EDIT_CERTIFICATE_MODAL_TITLE}
                                            text={EDIT_CERTIFICATE_MODAL_TEXT}
                                        />
                                    </form>
                                )
                            }}
                        </Formik>
                </CardBody>
            </Card>
        </>
    );
};

export default CertificateCreation

