import { all } from 'redux-saga/effects';

//public
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';
import changePasswordSaga from './auth/change-password/saga';
import employmentFormsSaga from './employmentForm/saga';
import updatePasswordSaga from './update-password/saga';
import cvSaga from "./cv/saga";

export default function* rootSaga() {
    yield all([
        //public
        AuthSaga(),
        ProfileSaga(),
        ForgetSaga(),
        LayoutSaga(),
        changePasswordSaga(),
        employmentFormsSaga(),
        updatePasswordSaga(),
        cvSaga()
    ])
}
