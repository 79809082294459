import * as yup from 'yup';

import {
    MIN_POSITION_LENGTH,
    MIN_CERTIFICATES_LENGTH,
    MIN_CITY_LENGTH,
    ZIP_CODE,
    ZIP_CODE_ERROR,
    POSITION_VALID_ERROR,
    CITY_VALID_ERROR,
    CERTIFICATES_VALID_ERROR, MIN_DESCRIPTION_LENGTH, DESCRIPTION_VALID_ERROR, MAX_DESCRIPTION_FIELD
} from '../../constants/job-creation-constants';



export const JOB_ADDRESS = yup
    .object()

export const JOB_POSITION = yup
    .string()

export const JOB_CERTIFICATES = yup
    .array().of(yup.string())


export const JOB_UNION = yup
    .boolean()

export const JOB_DESCRIPTION = yup
    .string()
    .min(MIN_DESCRIPTION_LENGTH, DESCRIPTION_VALID_ERROR)
    .max(MAX_DESCRIPTION_FIELD, DESCRIPTION_VALID_ERROR)

export const JOB_ZIP_CODE = yup
    .string()
    .matches(ZIP_CODE, ZIP_CODE_ERROR)


export const JOB_CITY = yup
    .string()
    .min(MIN_CITY_LENGTH, CITY_VALID_ERROR)
