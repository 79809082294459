import React, {useEffect, useMemo, useState} from "react";
import {Card, CardBody} from "reactstrap";
import Breadcrumb from "../../components/Common/breadcrumb";
import Switcher from "../../components/Switcher/Switcher";
import {useHistory, useParams} from "react-router-dom";

import certificatesService from "../../services/CertificatesService"
import {MAX_LENGTH} from "../../constants/job-creation-constants"
import {
    REQUIRED_STATUS,
    NOT_REQUIRED_STATUS, EDIT_CERTIFICATE_MODAL_TITLE, EDIT_CERTIFICATE_MODAL_TEXT, TYPE, editCertificateSwitchersData,
} from "../../constants/certificates-constants";
import {validationSchema} from "../../validators/CertificateForm/form";
import FormikInput from "../../components/FormikComponents/FormikInput";
import {Formik} from "formik";
import "./CertificateEdit.scss"
import FormikCheckbox from "../../components/FormikComponents/FormikCheckbox";
import ModalExample from "../../components/Modal/Modal";
import CloseModalButtons from "../../components/Modal/ModalButtonContainers/CloseModalButtons";


const CertificateEdit = () => {
    const [item, setItem] = useState({})
    const [isModal, setIsModal] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isChanged, setIsChanged] = useState(false)

    const isEmpty = item.hasOwnProperty(TYPE)
    const history = useHistory()
    const params = useParams();

    const newData = {...item}

    const breadcrumbs = useMemo(()=>( [
        {
            linkTo: "/certification-library/list-of-certificates",
            title: "Certification Library",
        },
        {
            linkTo: `/certification-library/detailed-certificate/${params.id}`,
            title: "Detailed Certificate",
        },
    ]),[params.id])

    useEffect(()=> {
        certificatesService.getCertificateById(params.id)
            .then(response => {
                setItem(response.data)
            })
    }, [params.id])

    const handleAddCertificateField = (name, status) => {
        setIsChanged(true)
        if(status){
            newData[name] = NOT_REQUIRED_STATUS;
        } else {
            delete  newData[name]
        }
        setItem(newData)
    }

    const handleSetCertificateFieldStatus = (name, status) => {
        if(item.hasOwnProperty(name)){
            newData[name] = status ? REQUIRED_STATUS : NOT_REQUIRED_STATUS
            setItem(newData)
        }
    }

    const updateCertificate = ({type, name}) => {
        const {issueDate, level, dateOfBirth, fullName, number, expirationDate} = item
        const data = {
            expirationDate,
            issueDate,
            level,
            dateOfBirth,
            fullName,
            class: item.class,
            number,
            type,
            name,
        }
        certificatesService.editCertificate(item.id, data)
            .then(()=> history.push(`/certification-library/detailed-certificate/${item.id}`))

    }

    const correctStatus = (value) => {
        return value === REQUIRED_STATUS;
    }

    const toggle = () => setIsModal(!isModal);

    const handleCancelButtonClick = (status) => {
        if(isChanged || status){
            toggle()
        } else {
            history.push(`/certification-library/detailed-certificate/${item.id}`)
        }
    }


    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} status={isDirty} toggle={toggle} />
            <Card className="creation-job-card">
                <CardBody className="creation-certificate-body-card">
                    <div className="creation-job-title">Certificate Edit Form</div>
                    {isEmpty &&
                    <Formik
                        initialValues={{type: item.type, name: item.name}}
                        validationSchema={validationSchema}
                        onSubmit={updateCertificate}
                        validateOnMount
                    >
                        {({errors, touched, handleSubmit, dirty, setFieldValue, values, isValid, ...p}) => {
                            setIsDirty(dirty)
                            return (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="certificate-fields-container">
                                        <div className="certificate-name-header ">Options</div>
                                        <div className="certificate-required-header">Required fields</div>
                                    </div>
                                    <div className="certificate-fields-container">
                                        <div className="certificate-name-field">
                                            <FormikInput
                                                id="type"
                                                label="Type*"
                                                name="type"
                                                containerClassName="certificate-field"
                                                placeholder="Add type"
                                                classNameInput="certificate-field-input"
                                                type="text"
                                                maxLength={MAX_LENGTH}
                                            />
                                        </div>
                                        <div className="certificate-required-field"></div>
                                    </div>

                                    <div className="certificate-fields-container">
                                        <div className="certificate-name-field">
                                            <FormikInput
                                                id="name"
                                                containerClassName="certificate-field"
                                                type="text"
                                                name="name"
                                                label="Name*"
                                                placeholder="Add name"
                                                classNameInput="certificate-field-input"
                                                maxLength={MAX_LENGTH}
                                            />
                                        </div>
                                        <div className="certificate-required-field"></div>
                                    </div>

                                    {editCertificateSwitchersData.map(({label, name}) => {
                                        return <div key={label}>
                                            <div className="certificate-fields-container">
                                                <div className="certificate-name-field">

                                                    <Switcher
                                                        status={!!item[name]}
                                                        name={name}
                                                        oneAddCertificateField={handleAddCertificateField}
                                                    />

                                                    <span className="certificate-field-label">{label}</span>
                                                </div>
                                                <div className="certificate-required-field">
                                                    <FormikCheckbox
                                                        name={name}
                                                        isChecked={correctStatus(item[name])}
                                                        isDisabled={!item[name]}
                                                        onChangeFieldStatus={handleSetCertificateFieldStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    }

                                    <div className="button-container">
                                        <button
                                            className="close-button"
                                            type="button"
                                            onClick={() => handleCancelButtonClick(dirty)}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className=" certificate-save-button"
                                            type="submit"
                                        >
                                            Save
                                        </button>
                                    </div>
                                    <ModalExample
                                        onToggle={toggle}
                                        container={<CloseModalButtons
                                            onToggle={toggle}
                                            path={`/certification-library/detailed-certificate/${item.id}`}
                                        />}
                                        id={item.id}
                                        isModal={isModal}
                                        title={EDIT_CERTIFICATE_MODAL_TITLE}
                                        text={EDIT_CERTIFICATE_MODAL_TEXT}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                    }
                </CardBody>
            </Card>
        </>
    );
};

export default CertificateEdit

