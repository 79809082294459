import * as yup from 'yup';
import {MIN_MESSAGE_FIELD, MIN_MESSAGE_FIELD_ERROR} from "../../constants/job-creation-constants";

export const EMAIL = yup
    .string()

export const SUBJECT = yup
    .string()

export const EMAIL_TEXT = yup
    .string()
    .min(MIN_MESSAGE_FIELD, MIN_MESSAGE_FIELD_ERROR)

