import Http from "./HttpService";


class EmployersService extends Http {

    createEmployer (model) {
        return this.post("admin-admins", model)
    }

    getEmployers( Offset, Limit=10) {
        return this.get(`admin-admins`, {params: {
            Limit,
            Offset}
        })
    }
    getEmployerById( id) {
        return this.get(`admin-admins/${id}`)
    }
    editEmployer(id, model){
        return this.put(`admin-admins/${id}`, model)
    }

    deleteEmployer(id){
        return this.delete(`admin-admins/${id}`)
    }
}


export default new EmployersService()