export const EMPLOYEES_LIST_COLUMNS = [
    {
        label: "First Name",
        field: "firstName",
        sort: "asc",
    },
    {
        label: "Last Name",
        field: "lastName",
        sort: "asc",
    },
    {
        label: "Email",
        field: "email",
        sort: "asc",
    },
    {
        label: "Phone Number",
        field: "phoneNumber",
        sort: "asc",
    },
    {
        label: "Date and Time",
        field: "date",
        sort: "asc",
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
    },
]

export const DELETE_EMPLOYEE_MODAL_TITLE = "Delete employee"
export const DELETE_EMPLOYEE_MODAL_TEXT = "Are You sure you want to delete the chosen Employee?"

export const EDIT_EMPLOYEE_MODAL_TITLE = "Close the form"
export const EDIT_EMPLOYEE_MODAL_TEXT = "Are You sure you want to close the form?"